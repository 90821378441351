// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
// redux
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import PageLayout from "../../../../../common/shell/PageLayout";
import * as BadgeHelper from "../../../../../common/badges/BadgeHelper";
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
// common
import * as geoscienceAustraliaRouteHelper from "../../../../geoscienceAustraliaRouteHelper";
// views
import NavBar, { TabsEnum } from "../../components/views/NavBarView";
import DataMonitoringListGridView from "./views/DataMonitoringListGridView";
import LinksView, { LinkDetail } from "../../../../common/links/LinksView";

interface IRouteParameters {
    reprocessingId: string;
    reprocessingProjectId: string;
}

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const params = useParams<IRouteParameters>();
    const { reprocessingId, reprocessingProjectId } = params;

    // on mount
    useEffect(() => {
        actions.initialiseRequested(Number(reprocessingId), Number(reprocessingProjectId));
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const badges = new Array<React.ReactNode>();
    if (!vm.isPublic) badges.push(<BadgeHelper.NotPublicReprocessingProjectBadge />);

    return (
        <>
            {vm.projectName && (
                <PageLayout
                    title={[`Project`, vm.projectName]}
                    badges={badges}
                    selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_ReprocessingsProjects_ReprocessingProjects}
                    onReloadRequested={() => {
                        props.initialiseRequested(Number(reprocessingId), Number(reprocessingProjectId));
                    }}
                >
                    <NavBar reprocessingId={Number(reprocessingId)} reprocessingProjectId={Number(reprocessingProjectId)} activeNavItem={TabsEnum.DataMonitoring} />
                    <LinksView
                        title="Reprocessing"
                        canViewLink={vm.permissions.canViewReprocessings}
                        linkTooltip="Click to view Reprocessing Data Monitoring."
                        links={[new LinkDetail(vm.reprocessingName, geoscienceAustraliaRouteHelper.reprocessingDataMonitoring(vm.reprocessingId!))]}
                    />
                    <DataMonitoringListGridView vm={vm} />
                </PageLayout>
            )}
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_ReprocessingsProjects_ReprocessingProjectDataMonitoring }), Actions.actionFactory)(Controller);
