// framework
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
// redux
import * as Models from "../models/models";
import validator from "../models/validator";
import * as Actions from "../redux/actions";
// api
import * as Client from "../../../../../api/Client";
// shell
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
// common
import * as toastHelper from "../../../../../common/toastHelper";
import PageLayout from "../../../../../common/shell/PageLayout";
import * as BadgeHelper from "../../../../../common/badges/BadgeHelper";
import StatusMessagesAlertsView from "../../../../../common/alerts/StatusMessagesAlertsView";
import ConflictAlertView from "../../../../../common/alerts/ConflictAlertView";
import { ValidationVisibilityEnum } from "../../../../../common/validation/ValidationModel";
import ConfirmationView, { DefaultButtonEnum } from "../../../../../common/confirmation/ConfirmationView";
// views
import NavBar, { TabsEnum } from "../../components/views/NavBarView";
import RelatedActivitiesListGridView from "./views/RelatedActivitiesListGridView";
import AssignRelatedActivityView from "./views/AssignRelatedActivityView";

interface IRouteParameters {
    surveyDataId: string;
}

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const params = useParams<IRouteParameters>();
    const { surveyDataId } = params;

    const [showAddElementDialog, setShowAddElementDialog] = useState(false);

    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const v = validator(vm, validationVisibility);

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItem, setDeleteItem] = useState<{ activityId?: number; activityType?: Client.SurveyDataRelatedActivityTypeEnum }>();

    // on mount
    useEffect(() => {
        actions.initialiseRequested(Number(surveyDataId));
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onAssignClicked = () => {
        if (!v.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);
        actions.addRequested(vm);
        window.scrollTo(0, 0);
        setShowAddElementDialog(false);
    };

    const onCancelClicked = () => {
        setShowAddElementDialog(false);
        actions.viewChanged(vm.clearStatusMessages());
        setValidationVisibility(ValidationVisibilityEnum.Messages);
    };

    const onDeleteShow = (activityId: number, activityType: Client.SurveyDataRelatedActivityTypeEnum) => {
        setShowDeleteConfirmation(true);
        setDeleteItem({ activityId, activityType });
    };

    const onDeleteCancel = () => {
        setDeleteItem({});
        setShowDeleteConfirmation(false);
    };

    const onDeleteAccept = () => {
        actions.deleteRequested(vm.surveyDataId!, vm.surveyDataVersionNumber!, deleteItem!.activityId!, deleteItem!.activityType!);
        setShowDeleteConfirmation(false);
    };

    const badges = new Array<React.ReactNode>();
    if (!vm.isPublic) badges.push(<BadgeHelper.NotPublicSurveyDataBadge />);

    return (
        <>
            {vm.relatedActivities && vm.publicTitle && (
                <PageLayout
                    title={[`Survey Data`, vm.pageHeader]}
                    badges={badges}
                    selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_ReportsAndData_SurveyData}
                    onReloadRequested={() => {
                        props.initialiseRequested(Number(surveyDataId));
                    }}
                >
                    <NavBar surveyDataId={Number(surveyDataId)} activeNavItem={TabsEnum.RelatedActivities} />
                    <ConflictAlertView isConflict={vm.isConflict} />
                    <StatusMessagesAlertsView statusMessages={vm.statusMessages} />
                    <RelatedActivitiesListGridView vm={vm} onDelete={onDeleteShow} />
                    <div className="mt-3">
                        <button
                            type="button"
                            className="btn btn-primary me-2"
                            onClick={() => {
                                actions.viewChanged(vm.clearStatusMessages());
                                setShowAddElementDialog(true);
                            }}
                        >
                            Assign Related Activity
                        </button>
                    </div>
                    {showAddElementDialog && <AssignRelatedActivityView vm={vm} v={v} actions={actions} onAssign={onAssignClicked} onCancel={onCancelClicked} />}
                    {showDeleteConfirmation && (
                        <ConfirmationView title="Unlink Related Activity" onAccepted={onDeleteAccept} onClosed={onDeleteCancel} defaultButton={DefaultButtonEnum.Cancel}>
                            <p>Are you sure you want to unlink this Activity?</p>
                        </ConfirmationView>
                    )}
                </PageLayout>
            )}
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_ReportsAndData_SurveyDataRelatedActivities }), Actions.actionFactory)(Controller);
