// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export enum ViewStateEnum {
    View = "geoscienceAustralia.wellsBoreholes.reportFileAssets.models.viewState.view",
    Edit = "geoscienceAustralia.wellsBoreholes.reportFileAssets.models.viewState.edit",
}

export interface IReportFileAssets {
    tapeId?: string | undefined;
    reportDataFormat?: Client.GetGeoscienceAustraliaReportDetailsReferenceDataReportDataFormat | undefined;
    reportMediaType?: Client.GetGeoscienceAustraliaReportDetailsReferenceDataReportMediaType | undefined;
    remarks?: string | undefined;
    reportConditionStatus?: Client.GetGeoscienceAustraliaReportDetailsReferenceDataReportConditionStatus | undefined;
    qcRemarks?: string | undefined;
    reportCopyStatus?: Client.GetGeoscienceAustraliaReportDetailsReferenceDataReportCopyStatus | undefined;
    reportLoanStatus?: Client.GetGeoscienceAustraliaReportDetailsReferenceDataReportLoanStatus | undefined;
    rddsStatus?: Client.GetGeoscienceAustraliaReportDetailsReferenceDataRddsStatus | undefined;
    rddsComments?: string | undefined;
    reportDataStatus?: Client.GetGeoscienceAustraliaReportDetailsReferenceDataReportDataStatus | undefined;
    consignment?: string | undefined;
}

export interface IReferenceData {
    reportDataFormats: Client.GetGeoscienceAustraliaReportDetailsReferenceDataReportDataFormat[];
    reportMediaTypes: Client.GetGeoscienceAustraliaReportDetailsReferenceDataReportMediaType[];
    reportConditionStatuses: Client.GetGeoscienceAustraliaReportDetailsReferenceDataReportConditionStatus[];
    reportCopyStatuses: Client.GetGeoscienceAustraliaReportDetailsReferenceDataReportCopyStatus[];
    reportLoanStatuses: Client.GetGeoscienceAustraliaReportDetailsReferenceDataReportLoanStatus[];
    rddsStatuses: Client.GetGeoscienceAustraliaReportDetailsReferenceDataRddsStatus[];
    reportDataStatuses: Client.GetGeoscienceAustraliaReportDetailsReferenceDataReportDataStatus[];
}

export interface IRootViewModel {
    reportId: number | undefined;
    reportVersionNumber: number | undefined;
    reportHeader: string | undefined;
    isPublic: boolean | undefined;

    reportFileAssets: IReportFileAssets;
    referenceData: IReferenceData;

    auditDto?: Client.SimpleAuditEventsDto;

    statusMessages?: Client.StatusMessagesDto;

    isDirty: boolean;
    isConflict: boolean;
    viewState: ViewStateEnum;

    refreshFileAssets(response: Client.GetGeoscienceAustraliaReportFileAssetsResponseDto): IRootViewModel;
    refreshReferenceData(response: Client.GetGeoscienceAustraliaReportFileAssetsReferenceDataResponseDto): IRootViewModel;
    refreshUpdateResponse(response: Client.UpdateGeoscienceAustraliaReportFileAssetsResponseDto): IRootViewModel;
    refreshConflict(): IRootViewModel;

    onEditState(): IRootViewModel;
    onFileAssetsChanged(fileAssets: IReportFileAssets): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.reportId = undefined;
        this.reportVersionNumber = undefined;
        this.reportHeader = undefined;
        this.isPublic = undefined;

        this.reportFileAssets = {};
        this.referenceData = {
            reportDataFormats: [],
            reportMediaTypes: [],
            reportConditionStatuses: [],
            reportCopyStatuses: [],
            reportLoanStatuses: [],
            rddsStatuses: [],
            reportDataStatuses: [],
        };

        this.isDirty = false;
        this.isConflict = false;
        this.viewState = ViewStateEnum.View;
    }

    public reportId: number | undefined;
    public reportVersionNumber: number | undefined;
    public reportHeader: string | undefined;
    public isPublic: boolean | undefined;

    public reportFileAssets: IReportFileAssets;
    public referenceData: IReferenceData;

    public auditDto?: Client.SimpleAuditEventsDto;

    public statusMessages?: Client.StatusMessagesDto;

    public isDirty: boolean;
    public isConflict: boolean;
    public viewState: ViewStateEnum;

    public refreshFileAssets(response: Client.GetGeoscienceAustraliaReportFileAssetsResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.reportId = response.reportId;
        vm.reportVersionNumber = response.reportVersionNumber;
        vm.reportHeader = response.reportHeader;
        vm.isPublic = response.isPublic;

        vm.reportFileAssets = {
            tapeId: response.tapeId,
            reportDataFormat: vm.referenceData.reportDataFormats.find((d) => d.id === response.reportDataFormatId)!,
            reportMediaType: vm.referenceData.reportMediaTypes.find((m) => m.id === response.reportMediaTypeId)!,
            remarks: response.remarks,
            reportConditionStatus: vm.referenceData.reportConditionStatuses.find((s) => s.id === response.reportConditionStatusId)!,
            qcRemarks: response.qcRemarks,
            reportCopyStatus: vm.referenceData.reportCopyStatuses.find((s) => s.id === response.reportCopyStatusId)!,
            reportLoanStatus: vm.referenceData.reportLoanStatuses.find((l) => l.id === response.reportLoanStatusId)!,
            rddsStatus: vm.referenceData.rddsStatuses.find((s) => s.id === response.rddsStatusId)!,
            rddsComments: response.rddsComments,
            reportDataStatus: vm.referenceData.reportDataStatuses.find((s) => s.id === response.reportDataStatusId)!,
            consignment: response.consignment,
        };

        vm.statusMessages = undefined;

        vm.isDirty = false;
        vm.isConflict = false;
        vm.auditDto = response.auditDto;

        vm.viewState = ViewStateEnum.View;

        return vm;
    }

    public refreshReferenceData(response: Client.GetGeoscienceAustraliaReportFileAssetsReferenceDataResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.referenceData = {
            reportDataFormats: response.reportDataFormats,
            reportMediaTypes: response.reportMediaTypes,
            reportConditionStatuses: response.reportConditionStatuses,
            reportCopyStatuses: response.reportCopyStatuses,
            reportLoanStatuses: response.reportLoanStatuses,
            rddsStatuses: response.rddsStatuses,
            reportDataStatuses: response.reportDataStatuses,
        };
        return vm;
    }

    public refreshUpdateResponse(response: Client.UpdateGeoscienceAustraliaReportFileAssetsResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = response.statusMessages;
        return vm;
    }

    public refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = true;
        return vm;
    }

    public onEditState(): IRootViewModel {
        const vm = this._clone();
        vm.viewState = ViewStateEnum.Edit;
        vm.statusMessages = undefined;
        return vm;
    }

    public onFileAssetsChanged(fileAssets: IReportFileAssets): IRootViewModel {
        const vm = this._clone();
        vm.reportFileAssets = fileAssets;
        vm.isDirty = true;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.reportFileAssets = clone(this.reportFileAssets);
        vm.referenceData = clone(this.referenceData);
        return vm;
    }
}
