// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";
// common
import { AdvancedSearchFilter, IAdvancedSearchFilter, IAdvancedSearchReferenceData } from "../../../../../common/advancedSearch/models";

export enum ViewStateEnum {
    Initialising,
    View,
    Searching,
    SelectBatchUpdate,
    EditBatchUpdate,
}

export interface IBatchUpdateViewModel {
    updateConfidentiality: boolean;
    confidentiality?: boolean | undefined;
    updateConfidentialityRemarks: boolean;
    confidentialityRemarksUpdateType: Client.ReportsStringUpdateTypeEnum;
    confidentialityRemarks?: string | undefined;
    updateReceivedDate: boolean;
    receivedDate?: Date | undefined;
    updateConsignment: boolean;
    consignmentUpdateType: Client.ReportsStringUpdateTypeEnum;
    consignment?: string | undefined;
    updateConditionStatus: boolean;
    conditionStatus: Client.GetGeoscienceAustraliaWellsBoreholesWellBoreholeReportsConditionStatusDto | undefined;
    updateLoanStatus: boolean;
    loanStatus: Client.GetGeoscienceAustraliaWellsBoreholesWellBoreholeReportsLoanStatusDto | undefined;
}

export interface IReferenceData {
    loanStatuses: Client.GetGeoscienceAustraliaReportListReferenceDataLoanStatusDto[];
    conditionStatuses: Client.GetGeoscienceAustraliaReportListReferenceDataConditionStatusDto[];
}

export interface IReportItemViewModel {
    id: number;
    versionNumber: number;
    itemId: string;
    publicTitle: string;
    reportType: string;
    isPublic: boolean;
    receivedDate: Date | undefined;
    numberOfMappings: number;
    numberOfAttachments: number;
    consignment: string;
    isConfidential: string | undefined;
    confidentialityRemarks: string;
    reportTitle: string;
    isSelected: boolean;
    updateFailed: boolean;
    loanStatus: string | undefined;
    conditionStatus: string | undefined;
    dateCreated: Date;
    dateLastModified: Date;
    relatedActivities: Client.GetGeoscienceAustraliaReportListRelatedActivityDto[];
    relatedActivitiesString: string | undefined;
}

export interface IRootViewModel {
    reports: IReportItemViewModel[];
    isResultSetLimited: boolean;
    referenceData: IReferenceData;

    canViewBoreholes: boolean;
    canViewAcquisitions: boolean;
    canViewProjects: boolean;

    searchText: string;
    advancedSearchFilter?: IAdvancedSearchFilter | undefined;
    advancedSearchReferenceData: IAdvancedSearchReferenceData;

    batchUpdateDetails: IBatchUpdateViewModel;

    statusMessages: Client.StatusMessagesDto | undefined;

    viewState: ViewStateEnum;

    isDirty: boolean;
    isConflict: boolean;

    refreshList(response: Client.GetGeoscienceAustraliaReportListResponseDto): IRootViewModel;
    refreshReferenceData(response: Client.GetGeoscienceAustraliaReportListReferenceDataResponseDto): IRootViewModel;
    refreshUpdate(response: Client.UpdateGeoscienceAustraliaReportsResponseDto): IRootViewModel;

    changeSearchFilter(searchText: string, advancedSearchFilter: IAdvancedSearchFilter): IRootViewModel;
    clearAllSearchFilters(): IRootViewModel;

    refreshConflict(): IRootViewModel;

    onReportUpdated(report: IReportItemViewModel): IRootViewModel;
    onBatchDetailsUpdate(batchUpdateDetails: IBatchUpdateViewModel): IRootViewModel;

    onSelectBatchUpdate(): IRootViewModel;
    onEditBatchUpdate(): IRootViewModel;
}

export class ReportItemViewModel implements IReportItemViewModel {
    constructor(report: Client.GetGeoscienceAustraliaReportListItemDto) {
        this.id = report.id;
        this.versionNumber = report.versionNumber;
        this.itemId = report.itemId;
        this.publicTitle = report.publicTitle;
        this.isPublic = report.isPublic;
        this.reportType = report.reportType;
        this.receivedDate = report.receivedDate;
        this.numberOfMappings = report.numberOfMappings;
        this.numberOfAttachments = report.numberOfAttachments;
        this.consignment = report.consignment;
        this.isConfidential = report.isConfidential;
        this.confidentialityRemarks = report.confidentialityRemarks;
        this.reportTitle = report.reportTitle;
        this.isSelected = false;
        this.updateFailed = false;
        this.loanStatus = report.loanStatus;
        this.conditionStatus = report.conditionStatus;
        this.dateCreated = report.dateCreated;
        this.dateLastModified = report.dateLastModified;
        this.relatedActivities = report.relatedActivities ?? [];
        this.relatedActivitiesString = report.relatedActivities?.map((activity) => `${activity.identifier} - ${activity.name}`).join("|");
    }

    public id: number;
    public versionNumber: number;
    public itemId: string;
    public publicTitle: string;
    public isPublic: boolean;
    public reportType: string;
    public receivedDate: Date | undefined;
    public numberOfMappings: number;
    public numberOfAttachments: number;
    public consignment: string;
    public isConfidential: string | undefined;
    public confidentialityRemarks: string;
    public reportTitle: string;
    public isSelected: boolean;
    public updateFailed: boolean;
    public loanStatus: string | undefined;
    public conditionStatus: string | undefined;
    public dateCreated: Date;
    public dateLastModified: Date;
    public relatedActivities: Client.GetGeoscienceAustraliaReportListRelatedActivityDto[];
    public relatedActivitiesString: string | undefined;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.reports = [];
        this.isResultSetLimited = false;
        this.referenceData = {
            loanStatuses: [],
            conditionStatuses: [],
        };
        this.advancedSearchReferenceData = {
            wellBoreholes: [],
            enos: [],
            searchTypes: [],
            searchOperations: [],
        };

        this.searchText = "";

        this.canViewBoreholes = false;
        this.canViewAcquisitions = false;
        this.canViewProjects = false;

        this.batchUpdateDetails = {
            updateConfidentiality: false,
            confidentiality: undefined,
            updateConfidentialityRemarks: false,
            confidentialityRemarksUpdateType: Client.ReportsStringUpdateTypeEnum.Append,
            confidentialityRemarks: undefined,
            updateReceivedDate: false,
            receivedDate: undefined,
            updateConsignment: false,
            consignmentUpdateType: Client.ReportsStringUpdateTypeEnum.Append,
            consignment: undefined,
            updateConditionStatus: false,
            conditionStatus: undefined,
            updateLoanStatus: false,
            loanStatus: undefined,
        };

        this.statusMessages = undefined;

        this.viewState = ViewStateEnum.Initialising;
        this.isDirty = false;
        this.isConflict = false;
    }

    public reports: IReportItemViewModel[];
    public isResultSetLimited: boolean;
    public referenceData: IReferenceData;

    public canViewBoreholes: boolean;
    public canViewAcquisitions: boolean;
    public canViewProjects: boolean;

    public searchText: string;
    public advancedSearchFilter?: IAdvancedSearchFilter | undefined;
    public advancedSearchReferenceData: IAdvancedSearchReferenceData;

    public batchUpdateDetails: IBatchUpdateViewModel;

    public statusMessages: Client.StatusMessagesDto | undefined;

    public viewState: ViewStateEnum;

    public isDirty: boolean;
    public isConflict: boolean;

    public refreshList(response: Client.GetGeoscienceAustraliaReportListResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.reports = response.items.map((i) => new ReportItemViewModel(i));
        vm.isResultSetLimited = response.isResultSetLimited;

        vm.canViewBoreholes = response.permissions.canViewBoreholes;
        vm.canViewAcquisitions = response.permissions.canViewAcquisitions;
        vm.canViewProjects = response.permissions.canViewProjects;

        vm.batchUpdateDetails = {
            updateConfidentiality: false,
            confidentiality: undefined,
            updateConfidentialityRemarks: false,
            confidentialityRemarksUpdateType: Client.ReportsStringUpdateTypeEnum.Append,
            confidentialityRemarks: undefined,
            updateReceivedDate: false,
            receivedDate: undefined,
            updateConsignment: false,
            consignmentUpdateType: Client.ReportsStringUpdateTypeEnum.Append,
            consignment: undefined,
            updateConditionStatus: false,
            conditionStatus: undefined,
            updateLoanStatus: false,
            loanStatus: undefined,
        };

        vm.viewState = ViewStateEnum.View;
        vm.statusMessages = undefined;

        vm.isDirty = false;
        vm.isConflict = false;

        return vm;
    }

    public refreshReferenceData(response: Client.GetGeoscienceAustraliaReportListReferenceDataResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.referenceData = {
            loanStatuses: response.loanStatuses,
            conditionStatuses: response.conditionStatuses,
        };

        vm.advancedSearchFilter = new AdvancedSearchFilter(response.advancedSearchTypes[0], response.advancedSearchOperations[0]);
        vm.advancedSearchReferenceData = {
            wellBoreholes: response.wellBoreholes,
            acquisitions: response.acquisitions,
            projects: response.projects,
            enos: response.enos,
            searchTypes: response.advancedSearchTypes,
            searchOperations: response.advancedSearchOperations,
        };
        return vm;
    }

    public refreshUpdate(response: Client.UpdateGeoscienceAustraliaReportsResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = response.statusMessages;

        vm.reports = vm.reports.map((r) => {
            if (!response.failedReportIds!.some((id) => id === r.id)) return r;

            return { ...r, updateFailed: true };
        });

        // reset batch update details if update succeeded
        if (response.statusMessages?.isSuccess) {
            vm.batchUpdateDetails = {
                updateConfidentiality: false,
                confidentiality: undefined,
                updateConfidentialityRemarks: false,
                confidentialityRemarksUpdateType: Client.ReportsStringUpdateTypeEnum.Append,
                confidentialityRemarks: undefined,
                updateReceivedDate: false,
                receivedDate: undefined,
                updateConsignment: false,
                consignmentUpdateType: Client.ReportsStringUpdateTypeEnum.Append,
                consignment: undefined,
                updateConditionStatus: false,
                conditionStatus: undefined,
                updateLoanStatus: false,
                loanStatus: undefined,
            };
        }

        return vm;
    }

    public changeSearchFilter(searchText: string, advancedSearchFilter: IAdvancedSearchFilter): IRootViewModel {
        const vm = this._clone();
        vm.searchText = searchText;
        vm.advancedSearchFilter = advancedSearchFilter;
        vm.viewState = ViewStateEnum.Searching;
        return vm;
    }

    public clearAllSearchFilters(): IRootViewModel {
        const vm = this._clone();
        vm.searchText = "";
        vm.advancedSearchFilter = new AdvancedSearchFilter(vm.advancedSearchReferenceData.searchTypes[0], vm.advancedSearchReferenceData.searchOperations[0]);
        vm.viewState = ViewStateEnum.Searching;
        return vm;
    }

    public refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = true;
        return vm;
    }

    public onReportUpdated(item: IReportItemViewModel): IRootViewModel {
        const vm = this._clone();

        vm.reports = vm.reports.map((r) => {
            if (r.id !== item.id) return r;
            return { ...r, isSelected: !r.isSelected };
        });

        vm.isDirty = true;
        return vm;
    }

    public onBatchDetailsUpdate(batchUpdateDetails: IBatchUpdateViewModel): IRootViewModel {
        const vm = this._clone();
        vm.batchUpdateDetails = batchUpdateDetails;
        return vm;
    }

    public onSelectBatchUpdate(): IRootViewModel {
        const vm = this._clone();
        vm.viewState = ViewStateEnum.SelectBatchUpdate;
        vm.statusMessages = undefined;
        return vm;
    }

    public onEditBatchUpdate(): IRootViewModel {
        const vm = this._clone();
        vm.viewState = ViewStateEnum.EditBatchUpdate;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.advancedSearchFilter = clone(this.advancedSearchFilter);
        return vm;
    }
}
