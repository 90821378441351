// framework
import React, { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
// models
import { IReportItemViewModel, IRootViewModel, ViewStateEnum } from "../../models/models";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import * as BadgeHelper from "../../../../../../common/badges/BadgeHelper";
import * as GeoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";
import CommentSummaryView from "../../../../../../common/commentSummary/commentSummaryView";
import ListSummaryView, { Orientation } from "../../../../../../common/listSummary/listSummaryView";
import { GetGeoscienceAustraliaReportListRelatedActivityDto, RelatedActivityEnum } from "../../../../../../api/Client";

export default function ReportListGridView(props: { vm: IRootViewModel; reportUpdated: (item: IReportItemViewModel) => void }): JSX.Element {
    const { vm, reportUpdated } = props;

    const [reportItemsState, setReportItemsState] = useState(new Array<IReportItemViewModel>());

    const initialState: State = {
        sort: [
            { field: "dateLastModified", dir: "desc" },
            { field: "receivedDate", dir: "desc" },
        ],
        filter: { logic: "and", filters: [] },
        skip: 0,
        take: 20,
    };

    const [gridState, setGridState] = useState(initialState);
    const [dataResult, setDataResult] = useState(process(reportItemsState, gridState));

    const [isUpdatingItem, setIsUpdatingItem] = useState(false);

    // reset grid state when data changes
    useEffect(() => {
        // If updating the item, don't reset the grid state.
        if (isUpdatingItem) {
            setIsUpdatingItem(false);
            setReportItemsState(vm.reports);
            setDataResult(process(vm.reports, gridState));
            return;
        }

        setGridState(initialState);
        setReportItemsState(vm.reports);
        setDataResult(process(vm.reports, initialState));
    }, [vm.reports]); // eslint-disable-line react-hooks/exhaustive-deps

    function onCheckedChanged(item: IReportItemViewModel) {
        setIsUpdatingItem(true);

        const updatedReportItemsState = reportItemsState.map((r) => {
            if (r.id !== item.id) return r;
            return { ...r, isSelected: r.isSelected };
        });

        setReportItemsState(updatedReportItemsState);
        setDataResult(process(updatedReportItemsState, gridState));
        reportUpdated(item);
    }

    function generatePopupViewEntry(canView: boolean, relatedActivityName: string, activity: GetGeoscienceAustraliaReportListRelatedActivityDto, url: string): ReactElement {
        return canView ? (
            <div className="row">
                <div className="col-sm-2">{relatedActivityName}</div>
                <div className="col-sm-10">
                    {
                        <Link to={url} className={`form-link${activity.selectedInAdvancedSearch ? " k-font-weight-bold" : ""}`} title={`Click to view ${relatedActivityName} Reports.`}>
                            {activity.identifier} - {activity.name}
                        </Link>
                    }
                </div>
            </div>
        ) : (
            <span className={`${activity.selectedInAdvancedSearch ? "k-font-weight-bold" : ""}`}>{activity.identifier}</span>
        );
    }

    function generateSummaryViewEntry(canView: boolean, relatedActivityName: string, activity: GetGeoscienceAustraliaReportListRelatedActivityDto, url: string): ReactElement {
        return canView ? (
            <Link to={url} className={`form-link${activity.selectedInAdvancedSearch ? " k-font-weight-bold" : ""}`} title={`Click to view ${relatedActivityName} Reports.`}>
                {activity.identifier}
            </Link>
        ) : (
            <span className={`${activity.selectedInAdvancedSearch ? "k-font-weight-bold" : ""}`}>{activity.identifier}</span>
        );
    }

    function generateSummaryView(activity: GetGeoscienceAustraliaReportListRelatedActivityDto, index: number): ReactElement {
        return (
            <span key={`RelatedActivitySummaryEntry${index}`}>
                {activity.type === RelatedActivityEnum.Acquisition &&
                    generateSummaryViewEntry(
                        vm.canViewAcquisitions,
                        "Acquisition",
                        activity,
                        GeoscienceAustraliaRouteHelper.acquisitionReports(activity.relatedActivityParentId, activity.relatedActivityId)
                    )}

                {activity.type === RelatedActivityEnum.Borehole &&
                    generateSummaryViewEntry(vm.canViewBoreholes, "Borehole", activity, GeoscienceAustraliaRouteHelper.boreholeReports(activity.relatedActivityParentId, activity.relatedActivityId))}

                {activity.type === RelatedActivityEnum.Project &&
                    generateSummaryViewEntry(
                        vm.canViewProjects,
                        "Project",
                        activity,
                        GeoscienceAustraliaRouteHelper.reprocessingProjectReports(activity.relatedActivityParentId, activity.relatedActivityId)
                    )}
            </span>
        );
    }

    function generatePopupView(activity: GetGeoscienceAustraliaReportListRelatedActivityDto, index: number): ReactElement {
        return (
            <span key={`RelatedActivityPopupEntry${index}`}>
                {activity.type === RelatedActivityEnum.Acquisition &&
                    generatePopupViewEntry(
                        vm.canViewAcquisitions,
                        "Acquisition",
                        activity,
                        GeoscienceAustraliaRouteHelper.acquisitionReports(activity.relatedActivityParentId, activity.relatedActivityId)
                    )}

                {activity.type === RelatedActivityEnum.Borehole &&
                    generatePopupViewEntry(vm.canViewBoreholes, "Borehole", activity, GeoscienceAustraliaRouteHelper.boreholeReports(activity.relatedActivityParentId, activity.relatedActivityId))}

                {activity.type === RelatedActivityEnum.Project &&
                    generatePopupViewEntry(
                        vm.canViewProjects,
                        "Project",
                        activity,
                        GeoscienceAustraliaRouteHelper.reprocessingProjectReports(activity.relatedActivityParentId, activity.relatedActivityId)
                    )}
            </span>
        );
    }

    useEffect(() => {
        switch (vm.viewState) {
            case ViewStateEnum.View:
                setDataResult(process(reportItemsState, gridState));
                break;
            case ViewStateEnum.SelectBatchUpdate:
                setDataResult(process(reportItemsState, gridState));
                break;
            case ViewStateEnum.EditBatchUpdate:
                setDataResult(
                    process(
                        vm.reports.filter((item) => item.isSelected),
                        initialState
                    )
                );
                break;
        }
    }, [vm.viewState, reportItemsState]); // eslint-disable-line react-hooks/exhaustive-deps

    const rowRender = (trElement: any, props: any) => {
        const failedStyle = {
            backgroundColor: "#F8D7DA",
        };
        const trProps = {
            style: props.dataItem.updateFailed ? failedStyle : undefined,
        };
        return React.cloneElement(
            trElement,
            {
                ...trProps,
            },
            trElement.props.children
        );
    };

    return (
        <>
            <Grid
                pageable
                sortable={vm.viewState === ViewStateEnum.EditBatchUpdate ? false : true}
                resizable
                navigatable
                rowRender={rowRender}
                data={dataResult}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                    setDataResult(process(reportItemsState, e.dataState));
                }}
                {...gridState}
            >
                {vm.viewState === ViewStateEnum.SelectBatchUpdate && (
                    <GridColumn
                        width={32}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props}>
                                <input type="checkbox" className={Forms.CheckInputClass} checked={props.dataItem.isSelected} onChange={() => onCheckedChanged(props.dataItem)} />
                            </CustomGridCell>
                        )}
                    />
                )}
                <GridColumn
                    field="itemId"
                    title="Item ID"
                    width={140}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <Link to={GeoscienceAustraliaRouteHelper.reportDetails(props.dataItem.id)} title="Click to view Report Details.">
                                {props.dataItem.itemId}
                            </Link>
                        </CustomGridCell>
                    )}
                    filter="text"
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : createGridColumnFilterMenu}
                />
                <GridColumn
                    field="relatedActivitiesString"
                    title="Related Activities"
                    width={450}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            {props.dataItem.relatedActivities && props.dataItem.relatedActivities.length > 0 && (
                                <ListSummaryView
                                    title="Related Activities"
                                    maxChildrenDisplayCount={6}
                                    popupListOrientation={Orientation.Vertical}
                                    children={props.dataItem.relatedActivities?.map((activity: GetGeoscienceAustraliaReportListRelatedActivityDto, index: number) => {
                                        return { displayElement: generateSummaryView(activity, index), popupDisplayElement: generatePopupView(activity, index) };
                                    })}
                                ></ListSummaryView>
                            )}
                        </CustomGridCell>
                    )}
                    filter="text"
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : createGridColumnFilterMenu}
                />
                <GridColumn
                    title="Report Title"
                    field="reportTitle"
                    width={500}
                    sortable={false}
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : createGridColumnFilterMenu}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            {props.dataItem.reportTitle}
                            {!props.dataItem.isPublic && <BadgeHelper.NotPublicReportBadge />}
                        </CustomGridCell>
                    )}
                />
                <GridColumn
                    field="reportType"
                    title="Report Type"
                    width={240}
                    filter="text"
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : (p) => createGridColumnCheckboxFilterMenu(p, vm.reports)}
                />
                <GridColumn
                    field="receivedDate"
                    title="Received Date"
                    width={210}
                    filter="date"
                    format={GlobalHelpers.NoptaDateFormatForKendo}
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : createGridColumnFilterMenu}
                />
                <GridColumn
                    field="numberOfMappings"
                    title="Total Mappings"
                    width={240}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <Link to={GeoscienceAustraliaRouteHelper.reportRelatedActivities(props.dataItem.id)} title="Click to view Report Related Elements.">
                                {props.dataItem.numberOfMappings}
                            </Link>
                        </CustomGridCell>
                    )}
                    filter="numeric"
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : createGridColumnFilterMenu}
                />
                <GridColumn
                    field="numberOfAttachments"
                    title="Total Attachments"
                    width={245}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <Link to={GeoscienceAustraliaRouteHelper.reportAttachments(props.dataItem.id)} title="Click to view Report Attachments.">
                                {props.dataItem.numberOfAttachments}
                            </Link>
                        </CustomGridCell>
                    )}
                    filter="numeric"
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : createGridColumnFilterMenu}
                />
                <GridColumn
                    field="isConfidential"
                    title="Confidentiality"
                    width={190}
                    filter="text"
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : (p) => createGridColumnCheckboxFilterMenu(p, vm.reports)}
                />
                <GridColumn
                    title="Confidentiality Remarks"
                    field="confidentialityRemarks"
                    width={250}
                    sortable={false}
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : createGridColumnFilterMenu}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props} className="k-command-cell">
                            <CommentSummaryView title="Confidentiality Remarks" comment={props.dataItem.confidentialityRemarks} maxSummaryLength={20}></CommentSummaryView>
                        </CustomGridCell>
                    )}
                />
                <GridColumn
                    field="conditionStatus"
                    title="Condition Status"
                    width={200}
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : (p) => createGridColumnCheckboxFilterMenu(p, vm.reports)}
                />
                <GridColumn
                    field="loanStatus"
                    title="Loan Status"
                    width={170}
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : (p) => createGridColumnCheckboxFilterMenu(p, vm.reports)}
                />
                <GridColumn
                    title="Consignment"
                    width={250}
                    sortable={false}
                    field="consignment"
                    filter="text"
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : createGridColumnFilterMenu}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props} className="k-command-cell">
                            <CommentSummaryView comment={props.dataItem.consignment} maxSummaryLength={20} title="Consignment"></CommentSummaryView>
                        </CustomGridCell>
                    )}
                />
                <GridColumn
                    field="dateCreated"
                    title="Created Date"
                    width={180}
                    filter="date"
                    format={GlobalHelpers.NoptaDateFormatForKendo}
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : createGridColumnFilterMenu}
                />
                <GridColumn
                    field="dateLastModified"
                    title="Last Modified Date"
                    width={230}
                    filter="date"
                    format={GlobalHelpers.NoptaDateFormatForKendo}
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : createGridColumnFilterMenu}
                />
            </Grid>
        </>
    );
}
