// framework
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// common
import * as GeoscienceAustraliaRouteHelper from "../../../../geoscienceAustraliaRouteHelper";
import ConfirmationView, { DefaultButtonEnum } from "../../../../../common/confirmation/ConfirmationView";
import CustomGridCell from "../../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
// models
import { ISeismicLineItemViewModel, IRootViewModel } from "../models/models";

export default function SurveyDataListGridView(props: {
    viewModel: IRootViewModel;
    onEdit: (item: ISeismicLineItemViewModel) => void;
    onDelete: (item: ISeismicLineItemViewModel) => void;
}): React.ReactElement {
    const { viewModel, onEdit, onDelete } = props;

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState<ISeismicLineItemViewModel | undefined>(undefined);
    const [seismicLineItemsState, setSeismicLineItemsState] = useState(new Array<ISeismicLineItemViewModel>());

    const initialState: State = {
        sort: [
            { field: "surveyAcquisition.name", dir: "asc" },
            { field: "lineName", dir: "asc" },
        ],
        filter: { logic: "and", filters: [] },
        skip: 0,
        take: 20,
    };

    const [gridState, setGridState] = useState(initialState);

    // reset grid state when data changes
    useEffect(() => {
        setGridState(initialState);
        setSeismicLineItemsState(viewModel.seismicLines);
    }, [viewModel.seismicLines]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSeismicLineItemsState(viewModel.seismicLines);
        setGridState(gridState);
    }, [viewModel.viewState]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Grid
                pageable
                sortable={true}
                resizable
                navigatable
                data={process(seismicLineItemsState, gridState)}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
                {...gridState}
            >
                <GridColumn
                    field="surveyAcquisition.name"
                    title="Acquisition"
                    width={400}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            {/* TODO Link to Acquisition Seismic Line tab when that has been implemented */}
                            <Link to={GeoscienceAustraliaRouteHelper.acquisitionDetails(viewModel.surveyId!, props.dataItem.surveyAcquisition.id)} title="Click to view Acquisition Details.">
                                {props.dataItem.surveyAcquisition.name}
                            </Link>
                        </CustomGridCell>
                    )}
                    filter="text"
                    columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, viewModel.seismicLines)}
                />

                <GridColumn field="lineName" title="Line Name" width={200} filter="text" columnMenu={createGridColumnFilterMenu} />
                <GridColumn field="firstShotPoint" title="First Shot Point" width={200} columnMenu={createGridColumnFilterMenu} />
                <GridColumn field="lastShotPoint" title="Last Shot Point" width={200} columnMenu={createGridColumnFilterMenu} />
                <GridColumn field="lineLength" title="Line Length (m)" width={200} filter="numeric" columnMenu={createGridColumnFilterMenu} />
                <GridColumn field="nominalShotInterval" title="Nominal Shot Interval" width={235} filter="numeric" columnMenu={createGridColumnFilterMenu} />
                <GridColumn field="shotIncrement" title="Shot Increment" width={225} filter="numeric" columnMenu={createGridColumnFilterMenu} />
                <GridColumn field="publicComments" title="Public Comments" width={400} filter="text" columnMenu={createGridColumnFilterMenu} />
                <GridColumn
                    width={140}
                    sortable={false}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props} className="k-command-cell">
                            <button
                                className="btn btn-secondary btn-sm"
                                type="button"
                                title="Edit"
                                onClick={() => {
                                    onEdit(props.dataItem);
                                }}
                            >
                                Edit
                            </button>

                            <button
                                className="btn btn-secondary btn-sm mx-2"
                                type="button"
                                title="Delete"
                                onClick={() => {
                                    setSelectedItem(props.dataItem);
                                    setShowDeleteDialog(true);
                                }}
                            >
                                Delete
                            </button>
                        </CustomGridCell>
                    )}
                />
            </Grid>

            {showDeleteDialog && (
                <ConfirmationView
                    title="Delete Seismic Line"
                    onAccepted={() => {
                        onDelete(selectedItem!);
                        setShowDeleteDialog(false);
                    }}
                    onClosed={() => setShowDeleteDialog(false)}
                    defaultButton={DefaultButtonEnum.Cancel}
                >
                    <p>Are you sure you want to delete this Seismic Line?</p>
                </ConfirmationView>
            )}
        </>
    );
}
