// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export enum ViewStateEnum {
    Initialising,
    View,
    Editing,
    Deleting,
}

export interface ISeismicLineItemViewModel {
    id: number | undefined;
    surveyAcquisition: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveySurveyDataSurveyAcquisitionItemDto | undefined;
    lineName: string;
    firstShotPoint: string | undefined;
    lastShotPoint: string | undefined;
    lineLength: number | undefined;
    nominalShotInterval: number | undefined;
    shotIncrement: number | undefined;
    publicComments: string | undefined;
}

export interface IRootViewModel {
    surveyId: number | undefined;
    surveyVersionNumber: number | undefined;
    surveyName: string | undefined;
    isPublic: boolean | undefined;

    seismicLines: ISeismicLineItemViewModel[];
    selectedSeismicLine: ISeismicLineItemViewModel | undefined;
    surveyAcquisitions: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveySurveyDataSurveyAcquisitionItemDto[];

    statusMessages: Client.StatusMessagesDto | undefined;

    viewState: ViewStateEnum;

    isBusy: boolean;
    isDirty: boolean;
    isConflict: boolean;

    refreshSelectedSurveySeismicLine(seismicLine: ISeismicLineItemViewModel | undefined): IRootViewModel;
    refreshSurveySeismicLines(response: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveySeismicLinesResponseDto): IRootViewModel;
    refreshConflict(): IRootViewModel;

    refreshIsBusy(isBusy: boolean): IRootViewModel;
    refreshViewState(viewState: ViewStateEnum): IRootViewModel;

    refreshStatusMessages(statusMessages: Client.StatusMessagesDto | undefined): IRootViewModel;
}

export class SeismicLineItemViewModel implements ISeismicLineItemViewModel {
    constructor(seismicLine: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveySeismicLineItemDto) {
        this.id = seismicLine.id;
        this.surveyAcquisition = seismicLine.surveyAcquisition!;
        this.lineName = seismicLine.lineName;
        this.firstShotPoint = seismicLine.firstShotPoint;
        this.lastShotPoint = seismicLine.lastShotPoint;
        this.lineLength = seismicLine.lineLength;
        this.nominalShotInterval = seismicLine.nominalShotInterval;
        this.shotIncrement = seismicLine.shotIncrement;
        this.publicComments = seismicLine.publicComments;
    }

    public id: number | undefined;
    public surveyAcquisition: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveySurveyDataSurveyAcquisitionItemDto;
    public lineName: string;
    public firstShotPoint: string | undefined;
    public lastShotPoint: string | undefined;
    public lineLength: number | undefined;
    public nominalShotInterval: number | undefined;
    public shotIncrement: number | undefined;
    public publicComments: string | undefined;
}

export class RootViewModel implements IRootViewModel {
    constructor(surveyId: number) {
        this.surveyId = surveyId;
        this.surveyName = undefined;
        this.isPublic = undefined;
        this.seismicLines = [];
        this.surveyAcquisitions = [];

        this.viewState = ViewStateEnum.Initialising;
        this.isBusy = false;
        this.isDirty = false;
        this.isConflict = false;
    }

    public surveyId: number | undefined;
    public surveyVersionNumber: number | undefined;
    public surveyName: string | undefined;
    public isPublic: boolean | undefined;

    public seismicLines: ISeismicLineItemViewModel[];
    public selectedSeismicLine: ISeismicLineItemViewModel | undefined;
    public surveyAcquisitions: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveySeismicLinesSurveyAcquisitionItemDto[];

    public statusMessages: Client.StatusMessagesDto | undefined;

    public viewState: ViewStateEnum;

    public isBusy: boolean;
    public isDirty: boolean;
    public isConflict: boolean;

    public refreshSelectedSurveySeismicLine(seismicLine: ISeismicLineItemViewModel | undefined): IRootViewModel {
        const vm = this._clone();

        vm.selectedSeismicLine = seismicLine;

        return vm;
    }

    public refreshSurveySeismicLines(response: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveySeismicLinesResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.surveyVersionNumber = response.surveyVersionNumber;
        vm.surveyName = response.surveyName;
        vm.isPublic = response.isPublic;
        vm.seismicLines = response.items.map((i) => new SeismicLineItemViewModel(i));
        vm.surveyAcquisitions = response.surveyAcquisitions;

        vm.viewState = ViewStateEnum.View;

        vm.statusMessages = undefined;

        vm.isDirty = false;
        vm.isConflict = false;

        return vm;
    }

    public refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = true;
        vm.viewState = ViewStateEnum.View;
        return vm;
    }

    public refreshIsBusy(isBusy: boolean): IRootViewModel {
        const vm = this._clone();
        vm.isBusy = isBusy;
        return vm;
    }

    public refreshViewState(viewState: ViewStateEnum): IRootViewModel {
        const vm = this._clone();
        vm.viewState = viewState;
        return vm;
    }

    public refreshStatusMessages(statusMessages: Client.StatusMessagesDto | undefined): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = statusMessages;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.seismicLines = clone(this.seismicLines);
        vm.surveyAcquisitions = clone(this.surveyAcquisitions);
        return vm;
    }
}
