// framework
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// redux
import * as Models from "./models/models";
import * as actions from "./actions";
// api
import { GetGeoscienceAustraliaSurveysAcquisitionsSurveySeismicLineItemDto } from "../../../../api/Client";
// common
import NavBar, { TabsEnum } from "../components/views/NavBarView";
import * as BadgeHelper from "../../../../common/badges/BadgeHelper";
import { Level1ItemTypeEnum } from "../../../../shell/layout/navigationItemTypes";
import * as GeoscienceAustraliaRouteHelper from "../../../geoscienceAustraliaRouteHelper";
import PageLayout from "../../../../common/shell/PageLayout";
import TaskLayout from "../../../../common/shell/TaskLayout";
import * as toastHelper from "../../../../common/toastHelper";
import LinksView, { LinkDetail } from "../../../common/links/LinksView";
import { ValidationVisibilityEnum } from "../../../../common/validation/ValidationModel";
// models
import validator from "./models/validator";
// views
import SeismicLineListGridView from "./views/SeismicLineListGridView";
import EditSeismicLineView from "./views/EditSeismicLineView";

interface IRouteParameters {
    surveyId: string;
}

export default function Controller(): JSX.Element {
    const params = useParams<IRouteParameters>();
    const { surveyId } = params;

    let [vm, setVm] = useState<Models.IRootViewModel>(new Models.RootViewModel(Number(surveyId)));

    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const v = validator(vm, validationVisibility);

    // on mount
    useEffect(() => {
        actions.getSeismicLines(vm, setVm);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onExport = () => {
        actions.exportSeismicLines(vm, setVm);
    };

    const onCreateShow = () => {
        setVm(
            vm
                .refreshViewState(Models.ViewStateEnum.Editing)
                .refreshSelectedSurveySeismicLine(new Models.SeismicLineItemViewModel(new GetGeoscienceAustraliaSurveysAcquisitionsSurveySeismicLineItemDto()))
                .refreshStatusMessages(undefined)
        );
    };

    const onSave = () => {
        if (!v.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }

        if (vm.selectedSeismicLine?.id === undefined) actions.createSeismicLine(vm, setVm);
        else actions.updateSeismicLine(vm, setVm);
    };

    const onDelete = (item: Models.ISeismicLineItemViewModel) => {
        actions.deleteSeismicLine(vm.refreshSelectedSurveySeismicLine(item), setVm);
    };

    const badges = new Array<React.ReactNode>();
    if (!vm.isPublic) badges.push(<BadgeHelper.NotPublicSurveyBadge />);

    return (
        <>
            {vm.surveyName && vm.surveyAcquisitions && (
                <PageLayout
                    title={[`Survey - ${vm.surveyName}`]}
                    badges={badges}
                    selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_SurveysAcquisitions_Surveys}
                    onReloadRequested={() => actions.getSeismicLines(vm, setVm)}
                >
                    <NavBar surveyId={Number(surveyId)} activeNavItem={TabsEnum.SeismicLines} />
                    <TaskLayout isBusy={vm.isBusy} statusMessages={vm.statusMessages} isConflict={vm.isConflict} isDirty={vm.isDirty}>
                        <LinksView
                            title="Acquisition(s)"
                            canViewLink={true}
                            linkTooltip="Click to view Acquisition Details"
                            links={vm.surveyAcquisitions.map(
                                (surveyAcquisition) => new LinkDetail(surveyAcquisition.name, GeoscienceAustraliaRouteHelper.acquisitionSurveyData(vm.surveyId!, surveyAcquisition.id))
                            )}
                        />
                        <SeismicLineListGridView
                            viewModel={vm}
                            onEdit={(item: Models.ISeismicLineItemViewModel) => {
                                setVm(vm.refreshViewState(Models.ViewStateEnum.Editing).refreshSelectedSurveySeismicLine(item));
                            }}
                            onDelete={(item) => {
                                onDelete(item);
                            }}
                        />

                        {vm.viewState === Models.ViewStateEnum.Editing && (
                            <EditSeismicLineView viewModel={vm} setViewModel={setVm} v={v} onSave={() => onSave()} onCancelUpdate={() => setVm(vm.refreshViewState(Models.ViewStateEnum.View))} />
                        )}

                        <div className="container-fluid p-2 form">
                            <div className="mt-3">
                                <button type="button" className="btn btn-primary me-2" onClick={onCreateShow} title="Create Seismic Line">
                                    Create
                                </button>
                                <button type="button" className="btn btn-primary me-2" onClick={onExport} title="Export Seismic Lines to Excel">
                                    Export
                                </button>
                            </div>
                        </div>
                    </TaskLayout>
                </PageLayout>
            )}
        </>
    );
}
