// common
import * as Validation from "../../../../../common/validation/ValidationModel";
// models
import { IRootViewModel } from "./models";

export default function validate(vm: IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);

    if (vm.selectedParameter && !vm.selectedParameter.surveyAcquisition) {
        v.addError("Acquisition", "Acquisition is required.");
    }

    if (vm.selectedParameter && vm.selectedParameter.shotPointInterval && vm.selectedParameter.shotPointInterval < 0) {
        v.addError("ShotPointInterval", "Shot Point Interval must be greater than 0.");
    }

    if (vm.selectedParameter && vm.selectedParameter.receiverGroupInterval && vm.selectedParameter.receiverGroupInterval < 0) {
        v.addError("ReceiverGroupInterval", "Receiver Group Interval must be greater than 0.");
    }

    if (vm.selectedParameter && vm.selectedParameter.groupChannelCount && vm.selectedParameter.groupChannelCount < 0) {
        v.addError("GroupChannelCount", "Group Channel Count must be greater than 0.");
    }

    return v;
}
