// framework
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// api
import { GetGeoscienceAustraliaSurveysAcquisitionsSurveyParametersResponseParameterDto } from "../../../../../api/Client";
// actions
import * as Models from "../models/models";
import * as actions from "../actions";
import validator from "../models/validator";
// common
import PageLayout from "../../../../../common/shell/PageLayout";
import * as BadgeHelper from "../../../../../common/badges/BadgeHelper";
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
import * as geoscienceAustraliaRouteHelper from "../../../../geoscienceAustraliaRouteHelper";
import TaskLayout from "../../../../../common/shell/TaskLayout";
import * as toastHelper from "../../../../../common/toastHelper";
import LinksView, { LinkDetail } from "../../../../common/links/LinksView";
import { ValidationVisibilityEnum } from "../../../../../common/validation/ValidationModel";
// views
import NavBar, { TabsEnum } from "../../components/views/NavBarView";
import ParametersListGridView from "./views/ParametersListGridView";
import EditParameterView from "./views/EditParameterView";

interface IRouteParameters {
    surveyId: string;
}

export default function Controller(): JSX.Element {
    const params = useParams<IRouteParameters>();
    const { surveyId } = params;

    let [vm, setVm] = useState<Models.IRootViewModel>(new Models.RootViewModel(Number(surveyId)));

    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const v = validator(vm, validationVisibility);

    // on mount
    useEffect(() => {
        actions.getParameters(vm, setVm);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onCreateShow = () => {
        setVm(
            vm
                .refreshViewState(Models.ViewStateEnum.Editing)
                .refreshSelectedParameter(new Models.ParameterItemViewModel(new GetGeoscienceAustraliaSurveysAcquisitionsSurveyParametersResponseParameterDto()))
                .refreshStatusMessages(undefined)
        );
    };

    const onSave = () => {
        if (!v.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }

        if (vm.selectedParameter?.id === undefined) actions.createParameter(vm, setVm);
        else actions.updateParameter(vm, setVm);
    };

    const onDelete = (item: Models.IParameterItemViewModel) => {
        actions.deleteParameter(vm.refreshSelectedParameter(item), setVm);
    };

    const badges = new Array<React.ReactNode>();
    if (!vm.isPublic) badges.push(<BadgeHelper.NotPublicSurveyBadge />);

    return (
        <>
            {vm.surveyName && (
                <PageLayout
                    title={[`Survey`, vm.surveyName]}
                    badges={badges}
                    selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_SurveysAcquisitions_Surveys}
                    onReloadRequested={() => {
                        actions.getParameters(vm, setVm);
                    }}
                >
                    <NavBar surveyId={Number(surveyId)} activeNavItem={TabsEnum.Parameters} />
                    <TaskLayout isBusy={vm.isBusy} statusMessages={vm.statusMessages} isConflict={vm.isConflict} isDirty={vm.isDirty}>
                        <LinksView
                            title="Acquisition(s)"
                            canViewLink={vm.canViewAcquisitions}
                            linkTooltip="Click to view Acquisition Parameters."
                            links={vm.surveyAcquisitions.map((a) => new LinkDetail(a.name, geoscienceAustraliaRouteHelper.acquisitionParameters(vm.surveyId!, a.id)))}
                        />
                        <ParametersListGridView
                            vm={vm}
                            onEdit={(item: Models.IParameterItemViewModel) => {
                                setVm(vm.refreshViewState(Models.ViewStateEnum.Editing).refreshSelectedParameter(item));
                            }}
                            onDelete={(item) => {
                                onDelete(item);
                            }}
                        />

                        {vm.viewState === Models.ViewStateEnum.Editing && (
                            <EditParameterView viewModel={vm} setViewModel={setVm} v={v} onSave={() => onSave()} onCancelUpdate={() => setVm(vm.refreshViewState(Models.ViewStateEnum.View))} />
                        )}
                        <div className="container-fluid p-2 form">
                            <div className="mt-3">
                                <button type="button" className="btn btn-primary me-2" onClick={onCreateShow} title="Create Seismic Line">
                                    Create
                                </button>
                            </div>
                        </div>
                    </TaskLayout>
                </PageLayout>
            )}
        </>
    );
}
