// framework
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
// models
import { IRootViewModel } from "../../models/models";
// common
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import * as BadgeHelper from "../../../../../../common/badges/BadgeHelper";
import ExternalLinkView from "../../../../../../common/externalLink/ExternalLinkView";
import * as geoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";
import * as shellRouteHelper from "../../../../../../shell/shellRouteHelper";

export default function WellListGridView(props: { vm: IRootViewModel }): JSX.Element {
    const { vm } = props;

    const initialState: State = {
        sort: [{ field: "spudDate", dir: "desc" }],
        filter: { logic: "and", filters: [] },
        skip: 0,
        take: 20,
    };

    const [gridState, setGridState] = useState(initialState);
    // reset grid state when data changes
    useEffect(() => {
        setGridState(initialState);
    }, [vm.wells]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Grid
            pageable
            sortable
            resizable
            navigatable
            data={process(vm.wells, gridState)}
            {...gridState}
            onDataStateChange={(e) => {
                setGridState(e.dataState);
            }}
        >
            <GridColumn
                field="uwi"
                title="UWI"
                width={150}
                cell={(props) => (
                    <CustomGridCell gridCellProps={props}>
                        <Link to={geoscienceAustraliaRouteHelper.wellDetails(props.dataItem.id)} title="Click to view Well.">
                            {props.dataItem.uwi}
                        </Link>
                    </CustomGridCell>
                )}
                filter="text"
                columnMenu={createGridColumnFilterMenu}
            />
            <GridColumn
                field="name"
                title="Name"
                width={300}
                filter="text"
                columnMenu={createGridColumnFilterMenu}
                cell={(props) => (
                    <CustomGridCell gridCellProps={props}>
                        <span style={{ display: "inline-block" }}>
                            {props.dataItem.name}
                            {!props.dataItem.isPublic ? <BadgeHelper.NotPublicWellBadge /> : ""}
                        </span>
                    </CustomGridCell>
                )}
            />
            {/* NOTE: Temporarily hiding Well Status (see 9549 for details) */}
            {/* <GridColumn field="status" title="Status" width={150} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.wells)} /> */}
            <GridColumn
                field="titleNumber"
                title="Title Number"
                width={185}
                cell={(props) => (
                    <CustomGridCell gridCellProps={props}>
                        {props.dataItem.titleId ? (
                            <ExternalLinkView href={shellRouteHelper.publicPortalTitleDetails(props.dataItem.titleNumber)}>{props.dataItem.titleNumber}</ExternalLinkView>
                        ) : (
                            props.dataItem.titleNumber
                        )}
                    </CustomGridCell>
                )}
                filter="text"
                columnMenu={createGridColumnFilterMenu}
            />
            <GridColumn field="titleholder" title="Titleholder" width={250} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.wells)} />
            <GridColumn field="region" title="Region" width={150} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.wells)} />
            <GridColumn field="isOffshore" title="Offshore" width={160} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.wells)} />
            <GridColumn field="spudDate" title="Spud Date" width={200} filter="date" format={GlobalHelpers.NoptaDateFormatForKendo} columnMenu={createGridColumnFilterMenu} />
        </Grid>
    );
}
