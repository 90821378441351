// framework
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
// kendo
import { DropDownButton, DropDownButtonItem } from "@progress/kendo-react-buttons";
import { caretAltDownIcon } from "@progress/kendo-svg-icons";
// redux
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import validator from "../models/validator";
import * as Actions from "../redux/actions";
// common
import DirtyScope from "../../../../../common/shell/DirtyScopeController";
import ConflictAlertView from "../../../../../common/alerts/ConflictAlertView";
import StatusMessagesAlertsView from "../../../../../common/alerts/StatusMessagesAlertsView";
import * as toastHelper from "../../../../../common/toastHelper";
import PageLayout from "../../../../../common/shell/PageLayout";
import { ValidationVisibilityEnum } from "../../../../../common/validation/ValidationModel";
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
import ValidationMessageView from "../../../../../common/validation/ValidationMessageView";
import * as GeoscienceAustraliaRouteHelper from "../../../../geoscienceAustraliaRouteHelper";
import { IAdvancedSearchFilter } from "../../../../../common/advancedSearch/models";
import SearchBarView from "../../../../../common/advancedSearch/SearchBarView";
import SimpleAlertView from "../../../../../common/simpleAlerts/SimpleAlertView";
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
// views
import ReportListGridView from "./views/ReportListGridView";
import ReportBatchUpdateView from "./views/ReportBatchUpdateView";

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const [showAdvanced, setShowAdvanced] = useState(false);

    // on mount
    useEffect(() => {
        actions.initialiseRequested();
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onSearchSubmit = (value: string, advancedFilter: IAdvancedSearchFilter) => {
        actions.viewChanged(vm.changeSearchFilter(value, advancedFilter));
        actions.searchRequested();
    };

    const onGridClear = () => {
        // note that this also triggers a grid state reset
        actions.viewChanged(vm.clearAllSearchFilters());
        actions.searchRequested();
    };

    const handleDropDownButtonItemClick = (event: any) => {
        if (event.item.text === "All Reports") {
            actions.exportRequested(false);
        }

        if (event.item.text === "Search Results") {
            actions.exportRequested(true);
        }
    };

    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const validation = validator(vm, validationVisibility);

    const history = useHistory();

    const onCreate = () => {
        history.push(GeoscienceAustraliaRouteHelper.reportCreate());
    };

    function onSelectBatchUpdate(): void {
        actions.viewChanged(vm.onSelectBatchUpdate());
    }

    function onEditBatchUpdate(): void {
        actions.viewChanged(vm.onEditBatchUpdate());
    }

    function onBatchUpdate(): void {
        if (!validation.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);
        actions.batchUpdateRequested(vm);
    }

    const searchTextDescription =
        "The Reports search returns records where the word or phrase is contained in either Item ID (formerly Store Barcode), Report Title, Report Type or Confidentiality Remarks.";
    const advancedSearchDescription =
        "The Advanced search filters are only applied when set, and returns records for a defined search from Boreholes, Acquisitions, Projects, ENOs (Wells, Boreholes, Surveys, Acquisitions, Reprocessing and Projects), Item IDs, Confidentiality Remarks (full text) and/or Comments (full text).";

    return (
        <>
            {vm.advancedSearchFilter && (
                <PageLayout title={["Search Reports"]} selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_ReportsAndData_Reports}>
                    {(vm.viewState === Models.ViewStateEnum.View || vm.viewState === Models.ViewStateEnum.Searching) && (
                        <>
                            <SearchBarView
                                searchText={vm.searchText}
                                searchTextDescription={searchTextDescription}
                                advancedSearchFilter={vm.advancedSearchFilter!}
                                advancedSearchDescription={advancedSearchDescription}
                                advancedSearchReferenceData={vm.advancedSearchReferenceData}
                                onSearchSubmit={onSearchSubmit}
                                onGridClear={onGridClear}
                                showAdvanced={showAdvanced}
                                setShowAdvanced={setShowAdvanced}
                            />
                        </>
                    )}
                    {vm.viewState !== Models.ViewStateEnum.Searching && (
                        <>
                            <ConflictAlertView isConflict={vm.isConflict} />
                            <StatusMessagesAlertsView statusMessages={vm.statusMessages} />
                            <DirtyScope scopeName="geoscienceAustralia/wellBoreholes/borehole/reports" isDirty={vm.isDirty}></DirtyScope>
                            {vm.isResultSetLimited && vm.viewState !== Models.ViewStateEnum.EditBatchUpdate && (
                                <SimpleAlertView
                                    alertType="warning"
                                    message={`For performance reasons, only the first ${GlobalHelpers.toNoptaNumberString(
                                        vm.reports.length,
                                        "n0"
                                    )} records have been returned. Please adjust your search criteria to locate relevant data.`}
                                    dismissable={true}
                                />
                            )}
                            <ReportListGridView vm={vm} reportUpdated={(report) => actions.viewChanged(vm.onReportUpdated(report))} />
                            {vm.viewState === Models.ViewStateEnum.SelectBatchUpdate && <ValidationMessageView name="batchUpdate.ItemSelected" validationModel={validation} />}
                            {vm.viewState === Models.ViewStateEnum.EditBatchUpdate && <ReportBatchUpdateView vm={vm} updateVm={(m) => actions.viewChanged(m)} v={validation} />}
                            <div className="container-fluid p-2 form">
                                <div className="mt-3">
                                    {vm.viewState === Models.ViewStateEnum.View && (
                                        <>
                                            <div className="row">
                                                {vm.reports.length > 0 && (
                                                    <div className="col-auto">
                                                        <button className="btn btn-primary" onClick={() => onSelectBatchUpdate()}>
                                                            Batch Update
                                                        </button>
                                                    </div>
                                                )}
                                                <div className="col-auto">
                                                    <button type="button" className="btn btn-primary" onClick={onCreate}>
                                                        Create Report
                                                    </button>
                                                </div>
                                                <div className="col-auto">
                                                    <DropDownButton text="Export to Excel" themeColor={"primary"} svgIcon={caretAltDownIcon} onItemClick={handleDropDownButtonItemClick}>
                                                        <DropDownButtonItem text="All Reports"></DropDownButtonItem>
                                                        <DropDownButtonItem text="Search Results" disabled={!vm.searchText && !showAdvanced}></DropDownButtonItem>
                                                    </DropDownButton>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {vm.viewState === Models.ViewStateEnum.SelectBatchUpdate && (
                                        <>
                                            <button
                                                className="btn btn-primary me-2"
                                                disabled={!vm.reports.some((item) => item.isSelected) || vm.reports.filter((item) => item.isSelected).length > 100}
                                                onClick={() => onEditBatchUpdate()}
                                            >
                                                Confirm Reports
                                            </button>
                                            <button className="btn btn-secondary me-2" onClick={() => actions.initialiseRequested()}>
                                                Cancel
                                            </button>
                                        </>
                                    )}
                                    {vm.viewState === Models.ViewStateEnum.EditBatchUpdate && (
                                        <>
                                            <button className="btn btn-primary me-2" onClick={() => onBatchUpdate()}>
                                                Batch Update
                                            </button>
                                            <button className="btn btn-secondary me-2" onClick={() => actions.initialiseRequested()}>
                                                Cancel
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </PageLayout>
            )}
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_WellsBoreholes_ReportList }), Actions.actionFactory)(Controller);
