// framework
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
// models
import { IRootViewModel } from "../../models/models";
// common
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import * as BadgeHelper from "../../../../../../common/badges/BadgeHelper";
import * as geoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";

export default function AcquisitionListGridView(props: { vm: IRootViewModel }): JSX.Element {
    const { vm } = props;

    const initialState: State = {
        sort: [{ field: "startDate", dir: "desc" }],
        filter: { logic: "and", filters: [] },
        skip: 0,
        take: 20,
    };

    const [gridState, setGridState] = useState(initialState);
    // reset grid state when data changes
    useEffect(() => {
        setGridState(initialState);
    }, [vm.acquisitions]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Grid
            pageable
            sortable
            resizable
            navigatable
            data={process(vm.acquisitions, gridState)}
            {...gridState}
            onDataStateChange={(e) => {
                setGridState(e.dataState);
            }}
        >
            <GridColumn
                field="acquisitionIdentifier"
                title="Acquisition ID"
                width={180}
                cell={(props) => (
                    <CustomGridCell gridCellProps={props}>
                        <Link to={geoscienceAustraliaRouteHelper.acquisitionDetails(props.dataItem.surveyId, props.dataItem.acquisitionId)} title="Click to view Acquisition details.">
                            {props.dataItem.acquisitionIdentifier}
                        </Link>
                    </CustomGridCell>
                )}
                filter="text"
                columnMenu={createGridColumnFilterMenu}
            />
            <GridColumn
                field="acquisitionName"
                title="Name"
                width={300}
                filter="text"
                columnMenu={createGridColumnFilterMenu}
                cell={(props) => (
                    <CustomGridCell gridCellProps={props}>
                        <>
                            {props.dataItem.acquisitionName}
                            {!props.dataItem.isPublic ? <BadgeHelper.NotPublicAcquisitionBadge /> : ""}
                        </>
                    </CustomGridCell>
                )}
            />
            <GridColumn field="acquisitionStatus" title="Status" width={150} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.acquisitions)} />
            <GridColumn field="acquisitionType" title="Type" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.acquisitions)} />
            <GridColumn field="acquisitionContractor" title="Contractor" width={160} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.acquisitions)} />
            <GridColumn field="surveyName" title="Survey Name" width={350} filter="text" columnMenu={createGridColumnFilterMenu} />
            <GridColumn field="surveyOperatorCompany" title="Operator" width={250} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.acquisitions)} />
            <GridColumn field="vessel" title="Vessel" width={150} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.acquisitions)} />
            <GridColumn field="startDate" title="Start Date" width={160} filter="date" format={GlobalHelpers.NoptaDateFormatForKendo} columnMenu={createGridColumnFilterMenu} />
            <GridColumn field="endDate" title="End Date" width={150} filter="date" format={GlobalHelpers.NoptaDateFormatForKendo} columnMenu={createGridColumnFilterMenu} />
            <GridColumn field="basicRelevantDate" title="Basic Relevant Date" width={220} filter="date" format={GlobalHelpers.NoptaDateFormatForKendo} columnMenu={createGridColumnFilterMenu} />
            <GridColumn
                field="interpretativeRelevantDate"
                title="Interpretative Relevant Date"
                width={270}
                filter="date"
                format={GlobalHelpers.NoptaDateFormatForKendo}
                columnMenu={createGridColumnFilterMenu}
            />
        </Grid>
    );
}
