// kendo
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
// common
import * as Forms from "../../../../../common/forms/BootstrapForms";
import KendoComboBox from "../../../../../common/kendo/KendoComboBox";
import ValidationMessageView from "../../../../../common/validation/ValidationMessageView";
import { IValidationModel } from "../../../../../common/validation/ValidationModel";
// models
import { IRootViewModel } from "../models/models";
import NumericTextBoxView from "../../../../../common/numericTextBox/NumericTextBoxView";

export default function EditSeismicLineView(props: {
    viewModel: IRootViewModel;
    setViewModel: (vm: IRootViewModel) => void;
    v: IValidationModel;
    onSave: () => void;
    onCancelUpdate: () => void;
}): React.ReactElement {
    const { viewModel, setViewModel, v, onSave, onCancelUpdate } = props;

    return (
        <Dialog title={`${viewModel.selectedSeismicLine?.id === undefined ? "Create" : "Edit"} Seismic Line`} width={650} onClose={onCancelUpdate}>
            <Forms.Row>
                <Forms.FullColumn>
                    <Forms.Label htmlFor="acquisitionField">Acquisition</Forms.Label>
                    <KendoComboBox
                        id="acquisitionField"
                        value={viewModel.selectedSeismicLine?.surveyAcquisition}
                        data={viewModel.surveyAcquisitions}
                        keyField="id"
                        textField="name"
                        valid={v.applyValidityForKendo("Acquisition")}
                        onChange={(value) => {
                            setViewModel(viewModel.refreshSelectedSurveySeismicLine({ ...viewModel.selectedSeismicLine!, surveyAcquisition: value }));
                        }}
                    />
                    <ValidationMessageView name="Acquisition" validationModel={v} />
                </Forms.FullColumn>
                <Forms.FullColumn>
                    <Forms.TextInput
                        label="Line Name"
                        id="lineNameField"
                        value={viewModel.selectedSeismicLine?.lineName}
                        maxLength={100}
                        onChange={(value: string) => setViewModel(viewModel.refreshSelectedSurveySeismicLine({ ...viewModel.selectedSeismicLine!, lineName: value }))}
                        valid={v.applyValidityForKendo("LineName")}
                    />
                    <ValidationMessageView name="LineName" validationModel={v} />
                </Forms.FullColumn>
                <Forms.FullColumn>
                    <Forms.TextInput
                        label="First Shot Point"
                        id="firstShotPointField"
                        value={viewModel.selectedSeismicLine?.firstShotPoint}
                        maxLength={100}
                        onChange={(value: string) => setViewModel(viewModel.refreshSelectedSurveySeismicLine({ ...viewModel.selectedSeismicLine!, firstShotPoint: value }))}
                    />
                </Forms.FullColumn>
                <Forms.FullColumn>
                    <Forms.TextInput
                        label="Last Shot Point"
                        id="lastShotPointField"
                        value={viewModel.selectedSeismicLine?.lastShotPoint}
                        maxLength={100}
                        onChange={(value: string) => setViewModel(viewModel.refreshSelectedSurveySeismicLine({ ...viewModel.selectedSeismicLine!, lastShotPoint: value }))}
                    />
                </Forms.FullColumn>
                <Forms.FullColumn>
                    <Forms.NumericInput
                        label="Line Length (m)"
                        id="lineLengthField"
                        value={viewModel.selectedSeismicLine?.lineLength?.toString()}
                        onChange={(value: string) => setViewModel(viewModel.refreshSelectedSurveySeismicLine({ ...viewModel.selectedSeismicLine!, lineLength: Number(value) }))}
                        onBlur={() => {}}
                    />
                </Forms.FullColumn>
                <Forms.FullColumn>
                    <Forms.NumericInput
                        label="Nominal Shot Interval"
                        id="nominalShotIntervalField"
                        value={viewModel.selectedSeismicLine?.nominalShotInterval?.toString()}
                        onChange={(value: string) => setViewModel(viewModel.refreshSelectedSurveySeismicLine({ ...viewModel.selectedSeismicLine!, nominalShotInterval: Number(value) }))}
                        onBlur={() => {}}
                    />
                </Forms.FullColumn>
                <Forms.FullColumn>
                    <Forms.Label htmlFor="shotIncrementField">Shot Increment</Forms.Label>
                    <NumericTextBoxView
                        id="shotIncrementField"
                        value={viewModel.selectedSeismicLine?.shotIncrement}
                        format="n2"
                        max={1000000}
                        valid={v.applyValidityForKendo("shotIncrementField")}
                        onChange={(value) => setViewModel(viewModel.refreshSelectedSurveySeismicLine({ ...viewModel.selectedSeismicLine!, shotIncrement: value }))}
                        onBlur={() => {}}
                        min={undefined}
                    />
                </Forms.FullColumn>
                <Forms.FullColumn>
                    <Forms.TextArea
                        label="Public Comments"
                        id="publicCommentsField"
                        value={viewModel.selectedSeismicLine?.publicComments}
                        maxLength={4000}
                        onChange={(value: string) => setViewModel(viewModel.refreshSelectedSurveySeismicLine({ ...viewModel.selectedSeismicLine!, publicComments: value }))}
                    />
                </Forms.FullColumn>
            </Forms.Row>
            <DialogActionsBar>
                <button type="button" className="btn btn-primary me-2" onClick={onSave}>
                    Save
                </button>
                <button type="button" className="btn btn-secondary me-2" onClick={onCancelUpdate}>
                    Cancel
                </button>
            </DialogActionsBar>
        </Dialog>
    );
}
