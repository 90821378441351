// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
// redux
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import PageLayout from "../../../../../common/shell/PageLayout";
import * as BadgeHelper from "../../../../../common/badges/BadgeHelper";
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
// common
import * as GeoscienceAustraliaRouteHelper from "../../../../geoscienceAustraliaRouteHelper";
import LinksView, { LinkDetail } from "../../../../common/links/LinksView";
// views
import NavBar, { TabsEnum } from "../../components/views/NavBarView";
import ParametersListGridView from "./views/ParametersListGridView";

interface IRouteParameters {
    surveyId: string;
    acquisitionId: string;
}

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const params = useParams<IRouteParameters>();
    const { surveyId, acquisitionId } = params;

    // on mount
    useEffect(() => {
        actions.initialiseRequested(Number(surveyId), Number(acquisitionId));
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const badges = new Array<React.ReactNode>();
    if (!vm.isPublic) badges.push(<BadgeHelper.NotPublicAcquisitionBadge />);

    return (
        <>
            {vm.acquisitionName && (
                <PageLayout
                    title={[`Acquisition`, vm.acquisitionName]}
                    badges={badges}
                    selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_SurveysAcquisitions_Acquisitions}
                    onReloadRequested={() => {
                        props.initialiseRequested(Number(surveyId), Number(acquisitionId));
                    }}
                >
                    <NavBar surveyId={Number(surveyId)} acquisitionId={Number(acquisitionId)} activeNavItem={TabsEnum.Parameters} />
                    <LinksView
                        title="Survey"
                        canViewLink={vm.canViewSurvey}
                        linkTooltip="Click to view Survey Parameters."
                        links={[new LinkDetail(vm.surveyName, GeoscienceAustraliaRouteHelper.surveyParameters(vm.surveyId!))]}
                    />
                    <ParametersListGridView vm={vm} />
                </PageLayout>
            )}
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_SurveysAcquisitions_AcquisitionParameter }), Actions.actionFactory)(Controller);
