// framework
import { useState } from "react";
// api
import { StatusMessagesDto } from "../../api/Client";
// views
import CompanyRegistrationController from "../../company/registration/components/RegistrationController";
import JointAuthorityRegistrationController from "../../jointAuthority/registration/components/RegistrationController";
import GeoscienceAustraliaRegistrationController from "../../geoscienceAustralia/registration/components/RegistrationController";
import RegistrationTypeView, { RegistrationType } from "./views/RegistrationTypeView";
import BodyLayout from "../../common/shell/BodyLayoutController";
import { Level1ItemTypeEnum } from "../../shell/layout/navigationItemTypes";
import StatusMessagesAlertsView from "../../common/alerts/StatusMessagesAlertsView";

export default function RegistrationController() {
    const [registrationType, setRegistrationType] = useState<RegistrationType | undefined>(undefined);
    const [statusMessages, setStatusMessages] = useState<StatusMessagesDto | undefined>(undefined);

    function onRegistrationTypeChange(registrationType: RegistrationType | undefined) {
        setRegistrationType(registrationType);
    }

    function getStatusMessages(statusMessages: StatusMessagesDto | undefined) {
        setStatusMessages(statusMessages);
    }

    return (
        <BodyLayout title={statusMessages?.isSuccess ? "Request Submitted" : "New User Request"} selectedLhsNavItem={Level1ItemTypeEnum.Common_Home_NewUserRequest}>
            <StatusMessagesAlertsView statusMessages={statusMessages} />
            <>
                {!statusMessages?.isSuccess && <RegistrationTypeView onSave={onRegistrationTypeChange} />}
                {registrationType === RegistrationType.Company && <CompanyRegistrationController statusMessages={getStatusMessages} />}
                {registrationType === RegistrationType.JointAuthority && <JointAuthorityRegistrationController statusMessages={getStatusMessages} />}
                {registrationType === RegistrationType.GeoscienceAustralia && <GeoscienceAustraliaRegistrationController statusMessages={getStatusMessages} />}
            </>
        </BodyLayout>
    );
}
