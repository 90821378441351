// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
// redux
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import PageLayout from "../../../../../common/shell/PageLayout";
import * as BadgeHelper from "../../../../../common/badges/BadgeHelper";
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
// common
import SimpleDataAuditChangesView from "../../../../../common/audit/SimpleDataAuditChangesView";
// views
import NavBar, { TabsEnum } from "../../components/views/NavBarView";

interface IRouteParameters {
    surveyDataId: string;
}

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const params = useParams<IRouteParameters>();
    const { surveyDataId } = params;

    // on mount
    useEffect(() => {
        actions.initialiseRequested(Number(surveyDataId));
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const badges = new Array<React.ReactNode>();
    if (!vm.surveyData?.isPublic) badges.push(<BadgeHelper.NotPublicSurveyDataBadge />);

    return (
        <>
            {vm.surveyData && (
                <PageLayout
                    title={[`Survey Data`, vm.surveyData.surveyDataHeader]}
                    badges={badges}
                    selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_ReportsAndData_SurveyData}
                    onReloadRequested={() => {
                        props.initialiseRequested(Number(surveyDataId));
                    }}
                >
                    <NavBar surveyDataId={Number(surveyDataId)} activeNavItem={TabsEnum.Auditing} />
                    <SimpleDataAuditChangesView changes={vm.surveyData.surveyDataDataAuditChanges} />
                </PageLayout>
            )}
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_ReportsAndData_SurveyDataAuditing }), Actions.actionFactory)(Controller);
