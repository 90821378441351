// framework
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
// models
import { IRootViewModel } from "../../models/models";
// common
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import * as BadgeHelper from "../../../../../../common/badges/BadgeHelper";
import * as geoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";

export default function BoreholeListGridView(props: { vm: IRootViewModel }): JSX.Element {
    const { vm } = props;

    const initialState: State = {
        sort: [{ field: "kickOffDate", dir: "desc" }],
        filter: { logic: "and", filters: [] },
        skip: 0,
        take: 20,
    };

    const [gridState, setGridState] = useState(initialState);
    // reset grid state when data changes
    useEffect(() => {
        setGridState(initialState);
    }, [vm.boreholes]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Grid
            pageable
            sortable
            resizable
            navigatable
            data={process(vm.boreholes, gridState)}
            {...gridState}
            onDataStateChange={(e) => {
                setGridState(e.dataState);
            }}
        >
            <GridColumn field="wellName" title="Well Name" width={170} filter="text" columnMenu={createGridColumnFilterMenu} />
            <GridColumn
                field="ubhi"
                title="UBHI"
                width={160}
                cell={(props) => (
                    <CustomGridCell gridCellProps={props}>
                        <Link to={geoscienceAustraliaRouteHelper.boreholeDetails(props.dataItem.wellId, props.dataItem.boreholeId)} title="Click to view Borehole.">
                            {props.dataItem.ubhi}
                        </Link>
                    </CustomGridCell>
                )}
                filter="text"
                columnMenu={createGridColumnFilterMenu}
            />
            <GridColumn
                field="boreholeName"
                title="Borehole Name"
                width={300}
                filter="text"
                columnMenu={createGridColumnFilterMenu}
                cell={(props) => (
                    <CustomGridCell gridCellProps={props}>
                        <>
                            {props.dataItem.boreholeName}
                            {!props.dataItem.isPublic ? <BadgeHelper.NotPublicBoreholeBadge /> : ""}
                        </>
                    </CustomGridCell>
                )}
            />
            <GridColumn field="boreholeOperator" title="Operator" width={250} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.boreholes)} />
            <GridColumn field="boreholeStatus" title="Status" width={180} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.boreholes)} />
            <GridColumn field="drilledBy" title="Drilled By" width={230} filter="text" columnMenu={createGridColumnFilterMenu} />
            <GridColumn
                field="rigName"
                title="Rig"
                width={230}
                filter="text"
                cell={(props) => (
                    <CustomGridCell gridCellProps={props}>
                        {props.dataItem.rigId && (
                            <Link to={geoscienceAustraliaRouteHelper.rigDetails(props.dataItem.rigId)} title="Click to view Rig.">
                                {props.dataItem.rigName}
                            </Link>
                        )}
                    </CustomGridCell>
                )}
                columnMenu={createGridColumnFilterMenu}
            />
            <GridColumn field="kickOffDate" title="Kick Off Date" width={170} filter="date" format={GlobalHelpers.NoptaDateFormatForKendo} columnMenu={createGridColumnFilterMenu} />
            <GridColumn field="rigReleaseDate" title="Rig Release Date" width={200} filter="date" format={GlobalHelpers.NoptaDateFormatForKendo} columnMenu={createGridColumnFilterMenu} />
            <GridColumn field="basicRelevantDate" title="Basic Relevant Date" width={220} filter="date" format={GlobalHelpers.NoptaDateFormatForKendo} columnMenu={createGridColumnFilterMenu} />
            <GridColumn
                field="interpretativeRelevantDate"
                title="Interpretative Relevant Date"
                width={270}
                filter="date"
                format={GlobalHelpers.NoptaDateFormatForKendo}
                columnMenu={createGridColumnFilterMenu}
            />
        </Grid>
    );
}
