// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IRootViewModel {
    reprocessingProjectId?: number;
    reprocessingProjectName?: string;
    reprocessingId: number | undefined;
    reprocessingName: string | undefined;
    isPublic: boolean | undefined;
    canViewReprocessings: boolean;
    attachments: Client.GetGeoscienceAustraliaReportAttachmentsItemDto[];
    isConflict: boolean;
    statusMessages?: Client.StatusMessagesDto;

    refreshReportAttachments(response: Client.GetGeoscienceAustraliaReprocessingProjectReportAttachmentsResponseDto): IRootViewModel;
    refreshConflict(isConflict: boolean): IRootViewModel;
    refreshStatusMessages(statusMessages: Client.StatusMessagesDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.attachments = [];
        this.isConflict = false;
        this.canViewReprocessings = false;
    }

    public reprocessingProjectId?: number;
    public reprocessingProjectName?: string;
    public reprocessingId: number | undefined;
    public reprocessingName: string | undefined;
    public isPublic: boolean | undefined;
    public canViewReprocessings: boolean;
    public attachments: Client.GetGeoscienceAustraliaReportAttachmentsItemDto[];
    public isConflict: boolean;
    public statusMessages?: Client.StatusMessagesDto;

    public refreshReportAttachments(response: Client.GetGeoscienceAustraliaReprocessingProjectReportAttachmentsResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.reprocessingProjectId = response.reprocessingProjectId;
        vm.reprocessingId = response.reprocessingId;
        vm.reprocessingProjectName = response.reprocessingProjectName;
        vm.reprocessingName = response.reprocessingName;
        vm.isPublic = response.isPublic;
        vm.canViewReprocessings = response.canViewReprocessing;
        vm.attachments = response.attachments;
        vm.isConflict = false;
        vm.statusMessages = new Client.StatusMessagesDto();

        return vm;
    }

    public refreshConflict(isConflict: boolean): IRootViewModel {
        const vm = this._clone();

        vm.isConflict = isConflict;

        return vm;
    }

    public refreshStatusMessages(statusMessages: Client.StatusMessagesDto): IRootViewModel {
        const vm = this._clone();

        vm.statusMessages = statusMessages;

        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.attachments = clone(this.attachments);
        return vm;
    }
}
