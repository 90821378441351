// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
// redux
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import PageLayout from "../../../../../common/shell/PageLayout";
import * as BadgeHelper from "../../../../../common/badges/BadgeHelper";
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
// common
import * as geoscienceAustraliaRouteHelper from "../../../../geoscienceAustraliaRouteHelper";
// views
import NavBar, { TabsEnum } from "../../components/views/NavBarView";
import DataMonitoringListGridView from "./views/DataMonitoringListGridView";
import LinksView, { LinkDetail } from "../../../../common/links/LinksView";

interface IRouteParameters {
    wellId: string;
    boreholeId: string;
}

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const params = useParams<IRouteParameters>();
    const { wellId, boreholeId } = params;

    // on mount
    useEffect(() => {
        actions.initialiseRequested(Number(wellId), Number(boreholeId));
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const badges = new Array<React.ReactNode>();
    if (vm.isPrimaryBorehole) badges.push(<BadgeHelper.IsPrimaryBadge />);
    if (!vm.isPublic) badges.push(<BadgeHelper.NotPublicBoreholeBadge />);

    return (
        <>
            {vm.boreholeName && (
                <PageLayout
                    title={[`Borehole`, vm.boreholeName]}
                    badges={badges}
                    selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_WellsBoreholes_Boreholes}
                    onReloadRequested={() => {
                        props.initialiseRequested(Number(wellId), Number(boreholeId));
                    }}
                >
                    <NavBar wellId={Number(wellId)} boreholeId={Number(boreholeId)} activeNavItem={TabsEnum.DataMonitoring} />
                    <LinksView
                        title="Well"
                        canViewLink={vm.permissions.canViewWells}
                        linkTooltip="Click to view Well Data Monitoring."
                        links={[new LinkDetail(vm.boreholeName, geoscienceAustraliaRouteHelper.wellDataMonitoring(vm.wellId!))]}
                    />
                    <DataMonitoringListGridView vm={vm} />
                </PageLayout>
            )}
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_WellsBoreholes_BoreholeDataMonitoring }), Actions.actionFactory)(Controller);
