// models
import { IRootViewModel } from "../../models/models";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import * as BadgeHelper from "../../../../../../common/badges/BadgeHelper";
import * as geoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";
import { Link } from "react-router-dom";

interface IProps {
    vm: IRootViewModel;
}

export default function wellBoreholesView(props: IProps): JSX.Element {
    const { vm } = props;

    return (
        <>
            {vm.wellBoreholes && vm.wellBoreholes.boreholes?.length < 1 && <span>No Borehole records available.</span>}
            {vm.wellBoreholes?.boreholes.map((bh) => (
                <div key={bh.id} className="card mb-2">
                    <div className="card-body">
                        <span className="card-title">
                            <>
                                <h2 style={{ display: "inline-block" }}>{`Borehole - ${bh.name}`}</h2>
                                <h4 style={{ display: "inline-block" }}>{bh.isPrimary && <BadgeHelper.IsPrimaryBadge />}</h4>
                                <h4 style={{ display: "inline-block" }}>{!bh.isPublic && <BadgeHelper.NotPublicBoreholeBadge />}</h4>
                            </>
                        </span>
                        <Forms.Row>
                            <Forms.FullColumn>
                                <Forms.Element label="UBHI">
                                    <Link to={geoscienceAustraliaRouteHelper.boreholeDetails(vm.wellId!, bh.id)} title="Click to view Borehole Details.">
                                        {bh.ubhi}
                                    </Link>
                                </Forms.Element>
                            </Forms.FullColumn>
                            <Forms.QuarterColumn>
                                <Forms.ReadonlyField id="reasonField" label="Reason" value={bh.purpose} />
                            </Forms.QuarterColumn>
                            <Forms.QuarterColumn>
                                <Forms.ReadonlyField id="rigField" label="Rig" value={bh.rig} />
                            </Forms.QuarterColumn>
                            <Forms.QuarterColumn>
                                <Forms.ReadonlyField id="boreholeTypeField" label="Borehole Type" value={bh.boreholeType} />
                            </Forms.QuarterColumn>
                            <Forms.QuarterColumn>
                                <Forms.ReadonlyField id="boreholeStatus" label="Borehole Status" value={bh.boreholeStatus} />
                            </Forms.QuarterColumn>
                            <Forms.HalfColumn>
                                <Forms.ReadonlyField id="boreholeOperatorField" label="Borehole Operator" value={bh.boreholeOperator} />
                            </Forms.HalfColumn>
                            <Forms.HalfColumn>
                                <Forms.ReadonlyField id="drillingCompanyField" label="Drilling Company" value={bh.drillingCompany} />
                            </Forms.HalfColumn>
                            <Forms.QuarterColumn>
                                <Forms.ReadonlyField id="kickOffDateField" label="Kick Off Date" value={GlobalHelpers.toNoptaDateString(bh.kickOffDate)} />
                            </Forms.QuarterColumn>
                            <Forms.QuarterColumn>
                                <Forms.ReadonlyField id="rigReleaseDateField" label="Rig Release Date" value={GlobalHelpers.toNoptaDateString(bh.rigReleaseDate)} />
                            </Forms.QuarterColumn>
                            <Forms.QuarterColumn>
                                <Forms.ReadonlyField id="basicRelevantDateField" label="Basic Relevant Date" value={GlobalHelpers.toNoptaDateString(bh.basicRelevantDate)} />
                            </Forms.QuarterColumn>
                            <Forms.QuarterColumn>
                                <Forms.ReadonlyField id="interpretativeRelevantDateField" label="Interpretative Relevant Date" value={GlobalHelpers.toNoptaDateString(bh.interpretativeRelevantDate)} />
                            </Forms.QuarterColumn>
                            <Forms.FullColumn>
                                <Forms.ReadonlyTextArea id="commentsField" label="Comments" value={bh.comments} />
                            </Forms.FullColumn>
                            <Forms.FullColumn>
                                <Forms.ReadonlyTextArea id="publicCommentsField" label="Public Comments" value={bh.publicComments} />
                            </Forms.FullColumn>
                        </Forms.Row>
                    </div>
                </div>
            ))}
        </>
    );
}
