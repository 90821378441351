import * as Models from "./models/models";
// api
import * as ClientFactory from "../../../../api/clientFactory";
import * as callHelper from "../../../../api/callHelper";
import * as Client from "../../../../api/Client";
// common
import * as LogHelper from "../../../../common/LogHelper";

export async function getParameters(vm: Models.IRootViewModel, setViewModel: (vm: Models.IRootViewModel) => void): Promise<void> {
    try {
        setViewModel((vm = vm.refreshIsBusy(true)));

        const client = await ClientFactory.createGeoscienceAustraliaSurveysAcquisitionsClient();

        const response = await callHelper.simpleCall(() =>
            client.getGeoscienceAustraliaSurveysAcquisitionsSurveyParameters(
                new Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyParametersRequestDto({
                    surveyId: vm.surveyId!,
                })
            )
        );

        setViewModel((vm = vm.refreshSurveyAcquisitionParameters(response)));
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        setViewModel((vm = vm.refreshIsBusy(false)));
    }
}

export async function createParameter(vm: Models.IRootViewModel, setViewModel: (vm: Models.IRootViewModel) => void): Promise<void> {
    try {
        setViewModel((vm = vm.refreshIsBusy(true)));

        const client = await ClientFactory.createGeoscienceAustraliaSurveysAcquisitionsClient();

        const createResponse = await callHelper.call(() =>
            client.createGeoscienceAustraliaSurveysAcquisitionsSurveyParameter(
                new Client.CreateGeoscienceAustraliaSurveysAcquisitionsSurveyParameterRequestDto({
                    surveyId: vm.surveyId!,
                    surveyVersionNumber: vm.surveyVersionNumber!,
                    surveyAcquisitionId: vm.selectedParameter?.surveyAcquisition!.id!,
                    shotPointInterval: vm.selectedParameter?.shotPointInterval,
                    receiverGroupInterval: vm.selectedParameter?.receiverGroupInterval,
                    groupChannelCount: vm.selectedParameter?.groupChannelCount,
                    surveyEnergySourceTypeId: vm.selectedParameter?.surveyEnergySourceType?.id,
                })
            )
        );

        if (createResponse.IsSuccess) {
            // success
            if (createResponse.Response!.statusMessages!.isSuccess) {
                // success
                const refreshResponse = await callHelper.simpleCall(() =>
                    client.getGeoscienceAustraliaSurveysAcquisitionsSurveyParameters(
                        new Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyParametersRequestDto({
                            surveyId: vm.surveyId!,
                        })
                    )
                );
                setViewModel((vm = vm.refreshSurveyAcquisitionParameters(refreshResponse).refreshStatusMessages(createResponse.Response!.statusMessages)));
            } // successful response, failed operation.
            else {
                setViewModel((vm = vm.refreshStatusMessages(createResponse.Response!.statusMessages)));
            }
        } else if (createResponse.IsConflict) {
            // conflict
            setViewModel((vm = vm.refreshConflict()));
        } else {
            // error
            createResponse.ShowToastNotification();
            setViewModel((vm = vm.refreshStatusMessages(undefined)));
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        setViewModel((vm = vm.refreshIsBusy(false)));
    }
}

export async function updateParameter(vm: Models.IRootViewModel, setViewModel: (vm: Models.IRootViewModel) => void): Promise<void> {
    try {
        setViewModel((vm = vm.refreshIsBusy(true)));

        const client = await ClientFactory.createGeoscienceAustraliaSurveysAcquisitionsClient();

        const updateResponse = await callHelper.call(() =>
            client.updateGeoscienceAustraliaSurveysAcquisitionsSurveyParameter(
                new Client.UpdateGeoscienceAustraliaSurveysAcquisitionsSurveyParameterRequestDto({
                    surveyId: vm.surveyId!,
                    surveyVersionNumber: vm.surveyVersionNumber!,
                    surveyParameterId: vm.selectedParameter?.id!,
                    surveyAcquisitionId: vm.selectedParameter?.surveyAcquisition!.id!,
                    shotPointInterval: vm.selectedParameter?.shotPointInterval,
                    receiverGroupInterval: vm.selectedParameter?.receiverGroupInterval,
                    groupChannelCount: vm.selectedParameter?.groupChannelCount,
                    surveyEnergySourceTypeId: vm.selectedParameter?.surveyEnergySourceType?.id,
                })
            )
        );

        if (updateResponse.IsSuccess) {
            // success
            if (updateResponse.Response!.statusMessages!.isSuccess) {
                const refreshResponse = await callHelper.simpleCall(() =>
                    client.getGeoscienceAustraliaSurveysAcquisitionsSurveyParameters(
                        new Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyParametersRequestDto({
                            surveyId: vm.surveyId!,
                        })
                    )
                );
                setViewModel((vm = vm.refreshSurveyAcquisitionParameters(refreshResponse).refreshStatusMessages(updateResponse.Response!.statusMessages)));
            } // successful response, failed operation.
            else {
                setViewModel((vm = vm.refreshStatusMessages(updateResponse.Response!.statusMessages)));
            }
        } else if (updateResponse.IsConflict) {
            // conflict
            setViewModel((vm = vm.refreshConflict()));
        } else {
            // error
            updateResponse.ShowToastNotification();
            setViewModel((vm = vm.refreshStatusMessages(undefined)));
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        setViewModel((vm = vm.refreshIsBusy(false)));
    }
}

export async function deleteParameter(vm: Models.IRootViewModel, setViewModel: (vm: Models.IRootViewModel) => void): Promise<void> {
    try {
        setViewModel((vm = vm.refreshIsBusy(true)));

        const client = await ClientFactory.createGeoscienceAustraliaSurveysAcquisitionsClient();

        const deleteResponse = await callHelper.call(() =>
            client.deleteGeoscienceAustraliaSurveysAcquisitionsSurveyParameter(
                new Client.DeleteGeoscienceAustraliaSurveysAcquisitionsSurveyParameterRequestDto({
                    surveyId: vm.surveyId!,
                    surveyVersionNumber: vm.surveyVersionNumber!,
                    surveyAcquisitionId: vm.selectedParameter!.surveyAcquisition!.id!,
                    surveyAcquisitionParameterId: vm.selectedParameter!.id!,
                })
            )
        );

        if (deleteResponse.IsSuccess) {
            // success
            if (deleteResponse.Response!.statusMessages!.isSuccess) {
                const refreshResponse = await callHelper.simpleCall(() =>
                    client.getGeoscienceAustraliaSurveysAcquisitionsSurveyParameters(
                        new Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyParametersRequestDto({
                            surveyId: vm.surveyId!,
                        })
                    )
                );
                setViewModel((vm = vm.refreshSurveyAcquisitionParameters(refreshResponse).refreshStatusMessages(deleteResponse.Response!.statusMessages)));
            }
            // successful response, failed operation.
            else {
                setViewModel((vm = vm.refreshStatusMessages(deleteResponse.Response!.statusMessages)));
            }
        } else if (deleteResponse.IsConflict) {
            // conflict
            setViewModel((vm = vm.refreshConflict()));
        } else {
            // error
            deleteResponse.ShowToastNotification();
            setViewModel((vm = vm.refreshStatusMessages(undefined)));
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        setViewModel((vm = vm.refreshIsBusy(false)));
    }
}
