// framework
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
// common
import ExternalLinkView from "../../../../../../common/externalLink/ExternalLinkView";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import * as BadgeHelper from "../../../../../../common/badges/BadgeHelper";
import CommentSummaryView from "../../../../../../common/commentSummary/commentSummaryView";
import * as shellRouteHelper from "../../../../../../shell/shellRouteHelper";
import * as geoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";
// api
import * as Client from "../../../../../../api/Client";
// models
import { IRootViewModel } from "../../models/models";

export default function ReprocessingProjectListGridView(props: { vm: IRootViewModel }): JSX.Element {
    const { vm } = props;

    const initialState: State = {
        sort: [{ field: "identifier", dir: "desc" }],
        filter: { logic: "and", filters: [] },
        skip: 0,
        take: 20,
    };

    const [gridState, setGridState] = useState(initialState);
    // reset grid state when data changes
    useEffect(() => {
        setGridState(initialState);
    }, [vm.reprocessingProjects]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Grid
                pageable
                sortable
                resizable
                navigatable
                data={process(vm.reprocessingProjects, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn
                    field="identifier"
                    title="Project ID"
                    width={200}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <Link to={geoscienceAustraliaRouteHelper.reprocessingProjectDetails(props.dataItem.reprocessingId, props.dataItem.projectId)} title="Click to view Project details.">
                                {props.dataItem.identifier}
                            </Link>
                        </CustomGridCell>
                    )}
                    filter="text"
                    columnMenu={createGridColumnFilterMenu}
                />
                <GridColumn
                    field="name"
                    title="Name"
                    width={450}
                    filter="text"
                    columnMenu={createGridColumnFilterMenu}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <>
                                {props.dataItem.name && props.dataItem.name.length > 100 ? (
                                    <span title={props.dataItem.name}>{props.dataItem.name.substring(0, 97) + `...`}</span>
                                ) : (
                                    props.dataItem.name
                                )}
                                {!props.dataItem.isPublic ? <BadgeHelper.NotPublicReprocessingProjectBadge /> : ""}
                            </>
                        </CustomGridCell>
                    )}
                />
                <GridColumn field="jurisdiction" title="Jurisdiction" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.reprocessingProjects)} />
                <GridColumn field="reprocessingType" title="Reprocessing Type" width={210} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.reprocessingProjects)} />
                <GridColumn
                    field="finalDeliverableDate"
                    title="Final Deliverable Date"
                    width={230}
                    filter="date"
                    format={GlobalHelpers.NoptaDateFormatForKendo}
                    columnMenu={createGridColumnFilterMenu}
                />
                <GridColumn field="permitYearEndDate" title="Permit Year End Date" width={230} filter="date" format={GlobalHelpers.NoptaDateFormatForKendo} columnMenu={createGridColumnFilterMenu} />
                <GridColumn field="confidential" title="Confidentiality" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.reprocessingProjects)} />
                <GridColumn field="contractorName" title="Contractor" width={300} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.reprocessingProjects)} />
                <GridColumn field="basicRelevantDate" title="Basic Relevant Date" width={220} filter="date" format={GlobalHelpers.NoptaDateFormatForKendo} columnMenu={createGridColumnFilterMenu} />
                <GridColumn
                    field="interpretativeRelevantDate"
                    title="Interpretative Relevant Date"
                    width={270}
                    filter="date"
                    format={GlobalHelpers.NoptaDateFormatForKendo}
                    columnMenu={createGridColumnFilterMenu}
                />
                <GridColumn
                    field="titleNumbers"
                    title="Title(s)"
                    width={400}
                    filter="text"
                    columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.reprocessingProjects)}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            {props.dataItem.titles.map((item: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingProjectListResponseTitleDto, index: number) => {
                                return (
                                    <span key={props.dataItem.identifier + item.titleNumber + item.titleId?.toString()}>
                                        {item.titleId ? <ExternalLinkView href={shellRouteHelper.publicPortalTitleDetails(item.titleNumber)}>{item.titleNumber}</ExternalLinkView> : item.titleNumber}

                                        {index < props.dataItem.titles!.length - 1 && <span>{", "}</span>}
                                    </span>
                                );
                            })}
                        </CustomGridCell>
                    )}
                />
                <GridColumn
                    field="comments"
                    title="Comments"
                    sortable={false}
                    width={250}
                    columnMenu={createGridColumnFilterMenu}
                    cell={(props: GridCellProps) => (
                        <CustomGridCell gridCellProps={props}>
                            <CommentSummaryView comment={props.dataItem.comments} maxSummaryLength={20} title="Comments"></CommentSummaryView>
                        </CustomGridCell>
                    )}
                />
            </Grid>
        </>
    );
}
