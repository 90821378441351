// framework
import { useEffect, useState } from "react";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import PageLayout from "../../../../../common/shell/PageLayout";
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
// common
import SimpleAlertView from "../../../../../common/simpleAlerts/SimpleAlertView";
import { IAdvancedSearchFilter } from "../../../../../common/advancedSearch/models";
import SearchBarView from "../../../../../common/advancedSearch/SearchBarView";
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
// views
import ReprocessingListGridView from "./views/ReprocessingListGridView";

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const [showAdvanced, setShowAdvanced] = useState(false);

    // on mount
    useEffect(() => {
        actions.initialiseRequested();
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onSearchSubmit = (value: string, advancedFilter: IAdvancedSearchFilter) => {
        actions.viewChanged(vm.changeSearchFilter(value, advancedFilter));
        actions.searchRequested();
    };

    const onGridClear = () => {
        // note that this also triggers a grid state reset
        actions.viewChanged(vm.clearAllSearchFilters());
        actions.initialiseRequested();
    };

    const searchTextDescription = `The Reprocessing search returns records where the word or phrase is contained in either Reprocessing ID, Name or Comments.`;
    const advancedSearchDescription = "The Advanced search filters are only applied when set, and returns records for a defined search from Titles and/or Acquisitions.";

    return (
        <>
            {vm.advancedSearchFilter && (
                <PageLayout title={["Search Reprocessing"]} selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_ReprocessingsProjects_Reprocessings}>
                    <SearchBarView
                        searchText={vm.searchText}
                        searchTextDescription={searchTextDescription}
                        advancedSearchFilter={vm.advancedSearchFilter!}
                        advancedSearchDescription={advancedSearchDescription}
                        advancedSearchReferenceData={vm.advancedSearchReferenceData}
                        onSearchSubmit={onSearchSubmit}
                        onGridClear={onGridClear}
                        showAdvanced={showAdvanced}
                        setShowAdvanced={setShowAdvanced}
                    />
                    {vm.isResultSetLimited && (
                        <SimpleAlertView
                            alertType="warning"
                            message={`For performance reasons, only the first ${GlobalHelpers.toNoptaNumberString(
                                vm.reprocessings.length,
                                "n0"
                            )} records have been returned. Please adjust your search criteria to locate relevant data.`}
                            dismissable={true}
                        />
                    )}
                    <ReprocessingListGridView vm={vm} />
                </PageLayout>
            )}
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_ReprocessingsProjects_ReprocessingList }), Actions.actionFactory)(Controller);
