// kendo
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import KendoComboBox from "../../../../../../common/kendo/KendoComboBox";
import ValidationMessageView from "../../../../../../common/validation/ValidationMessageView";
import { IValidationModel } from "../../../../../../common/validation/ValidationModel";
import NumericTextBoxView from "../../../../../../common/numericTextBox/NumericTextBoxView";
// models
import { IRootViewModel } from "../../models/models";

export default function EditParameterView(props: {
    viewModel: IRootViewModel;
    setViewModel: (vm: IRootViewModel) => void;
    v: IValidationModel;
    onSave: () => void;
    onCancelUpdate: () => void;
}): React.ReactElement {
    const { viewModel, setViewModel, v, onSave, onCancelUpdate } = props;

    return (
        <Dialog title={`${viewModel.selectedParameter?.id === undefined ? "Create" : "Edit"} Parameter`} width={650} onClose={onCancelUpdate}>
            <Forms.Row>
                <Forms.FullColumn>
                    <Forms.Label htmlFor="acquisitionField">Acquisition</Forms.Label>
                    <KendoComboBox
                        id="acquisitionField"
                        value={viewModel.selectedParameter?.surveyAcquisition}
                        data={viewModel.surveyAcquisitions}
                        keyField="id"
                        textField="name"
                        valid={v.applyValidityForKendo("Acquisition")}
                        onChange={(value) => {
                            setViewModel(viewModel.refreshSelectedParameter({ ...viewModel.selectedParameter!, surveyAcquisition: value }));
                        }}
                    />
                    <ValidationMessageView name="Acquisition" validationModel={v} />
                </Forms.FullColumn>
                <Forms.FullColumn>
                    <Forms.Label htmlFor="shotPointInterval">Shot Point Interval</Forms.Label>
                    <NumericTextBoxView
                        id="shotPointInterval"
                        value={viewModel.selectedParameter?.shotPointInterval}
                        format="n3"
                        valid={v.applyValidityForKendo("ShotPointInterval")}
                        onChange={(value) => setViewModel(viewModel.refreshSelectedParameter({ ...viewModel.selectedParameter!, shotPointInterval: value }))}
                        min={undefined}
                        max={undefined}
                    />
                    <ValidationMessageView name="ShotPointInterval" validationModel={v} />
                </Forms.FullColumn>
                <Forms.FullColumn>
                    <Forms.Label htmlFor="receiverGroupInterval">Receiver Group Interval</Forms.Label>
                    <NumericTextBoxView
                        id="receiverGroupInterval"
                        value={viewModel.selectedParameter?.receiverGroupInterval}
                        format="n3"
                        valid={v.applyValidityForKendo("ReceiverGroupInterval")}
                        onChange={(value) => setViewModel(viewModel.refreshSelectedParameter({ ...viewModel.selectedParameter!, receiverGroupInterval: value }))}
                        min={undefined}
                        max={undefined}
                    />
                    <ValidationMessageView name="ReceiverGroupInterval" validationModel={v} />
                </Forms.FullColumn>
                <Forms.FullColumn>
                    <Forms.Label htmlFor="groupChannelCount">Group Channel Count</Forms.Label>
                    <NumericTextBoxView
                        id="groupChannelCount"
                        value={viewModel.selectedParameter?.groupChannelCount}
                        format="n0"
                        max={1000000}
                        valid={v.applyValidityForKendo("GroupChannelCountField")}
                        onChange={(value) => setViewModel(viewModel.refreshSelectedParameter({ ...viewModel.selectedParameter!, groupChannelCount: value }))}
                        onBlur={() => {}}
                        min={undefined}
                    />
                    <ValidationMessageView name="GroupChannelCount" validationModel={v} />
                </Forms.FullColumn>
                <Forms.FullColumn>
                    <Forms.Label htmlFor="energySourceField">Energy Source</Forms.Label>
                    <KendoComboBox
                        id="energySourceField"
                        value={viewModel.selectedParameter?.surveyEnergySourceType}
                        data={viewModel.surveyEnergySourceTypes}
                        keyField="id"
                        textField="name"
                        valid={v.applyValidityForKendo("EnergySource")}
                        onChange={(value) => {
                            setViewModel(viewModel.refreshSelectedParameter({ ...viewModel.selectedParameter!, surveyEnergySourceType: value }));
                        }}
                    />
                    <ValidationMessageView name="EnergySource" validationModel={v} />
                </Forms.FullColumn>
            </Forms.Row>
            <DialogActionsBar>
                <button type="button" className="btn btn-primary me-2" onClick={onSave}>
                    Save
                </button>
                <button type="button" className="btn btn-secondary me-2" onClick={onCancelUpdate}>
                    Cancel
                </button>
            </DialogActionsBar>
        </Dialog>
    );
}
