// views
import BadgeView from "./BadgeView";

export function getPendingReviewBadge(toolTiptext?: string) {
    const titleText =
        toolTiptext === undefined
            ? "This user has a pending Request awaiting approval. The pending Request must be completed before the user's permissions for that company can be manually adjusted."
            : toolTiptext;
    return <BadgeView text="PR" title={titleText} variant="primary" />;
}

export function getCompanyThirtyDayWarningBadge() {
    return <BadgeView text="30" title="The User has no permissions for one or more of your Companies. The User will be removed in 30 days or less." variant="warning" />;
}

export function getGeoscienceAustraliaThirtyDayWarningBadge() {
    return <BadgeView text="30" title="The User has no permissions to Geoscience Australia. The User will be removed in 30 days or less." variant="warning" />;
}

export function getBlockedUserBadge(toolTiptext?: string) {
    const titleText =
        toolTiptext === undefined ? "This user has been blocked and can not access any company information. Please contact neats.admin@nopta.gov.au for further information." : toolTiptext;
    return <BadgeView text="BLKD" title={titleText} variant="danger" />;
}

export function IsPrimaryBadge(): React.ReactElement {
    return getBadge("Primary", "This is the Primary Borehole for the Well.", "#5d5d5d");
}

export function NotPublicWellBadge(): React.ReactElement {
    return <NotPublicBadge activity="Well" />;
}

export function NotPublicBoreholeBadge(): React.ReactElement {
    return <NotPublicBadge activity="Borehole" />;
}

export function NotPublicSurveyBadge(): React.ReactElement {
    return <NotPublicBadge activity="Survey" />;
}

export function NotPublicAcquisitionBadge(): React.ReactElement {
    return <NotPublicBadge activity="Acquisition" />;
}

export function NotPublicReprocessingBadge(): React.ReactElement {
    return <NotPublicBadge activity="Reprocessing" />;
}

export function NotPublicReprocessingProjectBadge(): React.ReactElement {
    return <NotPublicBadge activity="Project" />;
}

export function NotPublicReportBadge(): React.ReactElement {
    return <NotPublicBadge activity="Report" />;
}

export function NotPublicSurveyDataBadge(): React.ReactElement {
    return <NotPublicBadge activity="Survey Data" />;
}

function NotPublicBadge(props: { activity: string }): React.ReactElement {
    return getBadge("Not Public", `This ${props.activity} is not publicly visible.`, "#5d5d5d");
}

function getBadge(text: string, tooltip: string, backgroundColor: string): React.ReactElement {
    return (
        <span className="mx-1 badge rounded-pill" style={{ backgroundColor: backgroundColor }} data-bs-toggle="tooltip" data-placement="bottom" title={tooltip}>
            {text}
        </span>
    );
}
