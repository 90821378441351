// framework
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
// redux
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import validator from "../models/validator";
import * as Actions from "../redux/actions";
// common
import * as toastHelper from "../../../../../common/toastHelper";
import PageLayout from "../../../../../common/shell/PageLayout";
import * as BadgeHelper from "../../../../../common/badges/BadgeHelper";
import { ValidationVisibilityEnum } from "../../../../../common/validation/ValidationModel";
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
import * as GeoscienceAustraliaRouteHelper from "../../../../geoscienceAustraliaRouteHelper";
import DirtyScope from "../../../../../common/shell/DirtyScopeController";
import ConflictAlertView from "../../../../../common/alerts/ConflictAlertView";
import StatusMessagesAlertsView from "../../../../../common/alerts/StatusMessagesAlertsView";
import ValidationMessageView from "../../../../../common/validation/ValidationMessageView";
// views
import NavBar, { TabsEnum } from "../../components/views/NavBarView";
import SurveyDataListGridView from "./views/SurveyDataListGridView";
import SurveyDataBatchUpdateView from "./views/SurveyDataBatchUpdateView";
import LinksView, { LinkDetail } from "../../../../common/links/LinksView";
// api
import { CreateSurveyDataRelatedActivityTypeEnum } from "../../../../../api/Client";

interface IRouteParameters {
    surveyId: string;
}

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const params = useParams<IRouteParameters>();
    const { surveyId } = params;

    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const validation = validator(vm, validationVisibility);

    const history = useHistory();
    const onCreate = () => {
        history.push(GeoscienceAustraliaRouteHelper.surveyDataCreate(CreateSurveyDataRelatedActivityTypeEnum.SurveyAcquisition, vm.surveyId));
    };

    function onSelectBatchUpdate(): void {
        actions.viewChanged(vm.onSelectBatchUpdate());
    }

    function onEditBatchUpdate(): void {
        actions.viewChanged(vm.onEditBatchUpdate());
    }

    function onBatchUpdate(): void {
        if (!validation.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);
        actions.batchUpdateRequested(vm);
    }

    // on mount
    useEffect(() => {
        actions.initialiseRequested(Number(surveyId));
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const badges = new Array<React.ReactNode>();
    if (!vm.isPublic) badges.push(<BadgeHelper.NotPublicSurveyBadge />);

    return (
        <>
            {vm.surveyName && vm.surveyAcquisitions && (
                <PageLayout
                    title={[`Survey`, vm.surveyName]}
                    badges={badges}
                    selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_SurveysAcquisitions_Surveys}
                    onReloadRequested={() => {
                        props.clearRequested();
                        props.initialiseRequested(Number(surveyId));
                    }}
                >
                    <NavBar surveyId={Number(surveyId)} activeNavItem={TabsEnum.SurveyData} />
                    <ConflictAlertView isConflict={vm.isConflict} />
                    <StatusMessagesAlertsView statusMessages={vm.statusMessages} />
                    <DirtyScope scopeName="geoscienceAustralia/surveyAcquisitions/survey/surveyData" isDirty={vm.isDirty}></DirtyScope>
                    <LinksView
                        title="Acquisition(s)"
                        canViewLink={true}
                        linkTooltip="Click to view Acquisition Survey Data"
                        links={vm.surveyAcquisitions.map(
                            (surveyAcquisition) => new LinkDetail(surveyAcquisition.name, GeoscienceAustraliaRouteHelper.acquisitionSurveyData(vm.surveyId!, surveyAcquisition.id))
                        )}
                    />
                    <SurveyDataListGridView vm={vm} surveyDataUpdated={(surveyData) => actions.viewChanged(vm.onSurveyDataUpdated(surveyData))} />
                    {vm.viewState === Models.ViewStateEnum.SelectBatchUpdate && <ValidationMessageView name="batchUpdate.ItemSelected" validationModel={validation} />}
                    {vm.viewState === Models.ViewStateEnum.EditBatchUpdate && <SurveyDataBatchUpdateView vm={vm} updateVm={(m) => actions.viewChanged(m)} v={validation} />}
                    <div className="container-fluid p-2 form">
                        <div className="mt-3">
                            {vm.viewState === Models.ViewStateEnum.View && (
                                <>
                                    {vm.surveyData.length > 0 && (
                                        <button className="btn btn-primary me-2" onClick={() => onSelectBatchUpdate()}>
                                            Batch Update
                                        </button>
                                    )}
                                    <button type="button" className="btn btn-primary me-2" onClick={onCreate}>
                                        Create Survey Data
                                    </button>
                                </>
                            )}
                            {vm.viewState === Models.ViewStateEnum.SelectBatchUpdate && (
                                <>
                                    <button
                                        className="btn btn-primary me-2"
                                        disabled={!vm.surveyData.some((item) => item.isSelected) || vm.surveyData.filter((item) => item.isSelected).length > 100}
                                        onClick={() => onEditBatchUpdate()}
                                    >
                                        Confirm Survey Data
                                    </button>
                                    <button className="btn btn-secondary me-2" onClick={() => actions.initialiseRequested(Number(surveyId))}>
                                        Cancel
                                    </button>
                                </>
                            )}
                            {vm.viewState === Models.ViewStateEnum.EditBatchUpdate && (
                                <>
                                    <button className="btn btn-primary me-2" onClick={() => onBatchUpdate()}>
                                        Batch Update
                                    </button>
                                    <button className="btn btn-secondary me-2" onClick={() => actions.initialiseRequested(Number(surveyId))}>
                                        Cancel
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </PageLayout>
            )}
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_SurveysAcquisitions_SurveySurveyData }), Actions.actionFactory)(Controller);
