// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export interface IRootViewModel {
    reprocessingId: number | undefined;
    projectId: number | undefined;
    reprocessingName: string | undefined;
    projectName: string | undefined;
    isPublic: boolean | undefined;
    items: Client.GetGeoscienceAustraliaReprocessingsProjectsProjectDataMonitoringItemsResponseItemDto[];
    permissions: {
        canViewReprocessings: boolean;
        canViewDataSubmissions: boolean;
        canViewAuthorisations: boolean;
    };

    refreshProjectDataMonitoring(response: Client.GetGeoscienceAustraliaReprocessingsProjectsProjectDataMonitoringItemsResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.reprocessingId = undefined;
        this.projectId = undefined;
        this.reprocessingName = undefined;
        this.projectName = undefined;
        this.isPublic = undefined;
        this.items = [];
        this.permissions = {
            canViewReprocessings: false,
            canViewDataSubmissions: false,
            canViewAuthorisations: false,
        };
    }

    public reprocessingId: number | undefined;
    public projectId: number | undefined;
    public reprocessingName: string | undefined;
    public projectName: string | undefined;
    public isPublic: boolean | undefined;
    public items: Client.GetGeoscienceAustraliaReprocessingsProjectsProjectDataMonitoringItemsResponseItemDto[];
    public permissions: {
        canViewReprocessings: boolean;
        canViewDataSubmissions: boolean;
        canViewAuthorisations: boolean;
    };

    public refreshProjectDataMonitoring(response: Client.GetGeoscienceAustraliaReprocessingsProjectsProjectDataMonitoringItemsResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.reprocessingId = response.reprocessingId;
        vm.projectId = response.projectId;
        vm.reprocessingName = response.reprocessingName;
        vm.projectName = response.projectName;
        vm.isPublic = response.isPublic;
        vm.items = response.items;
        vm.permissions = {
            canViewReprocessings: response.canViewReprocessings,
            canViewDataSubmissions: response.canViewDataSubmissions,
            canViewAuthorisations: response.canViewAuthorisations,
        };
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.items = clone(this.items);
        return vm;
    }
}
