// framework
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
// redux
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// common
import PageLayout from "../../../../../common/shell/PageLayout";
import * as BadgeHelper from "../../../../../common/badges/BadgeHelper";
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
import * as GeoscienceAustraliaRouteHelper from "../../../../geoscienceAustraliaRouteHelper";
import DirtyScope from "../../../../../common/shell/DirtyScopeController";
import ConflictAlertView from "../../../../../common/alerts/ConflictAlertView";
import StatusMessagesAlertsView from "../../../../../common/alerts/StatusMessagesAlertsView";
import * as toastHelper from "../../../../../common/toastHelper";
// views
import NavBar, { TabsEnum } from "../../components/views/NavBarView";
import ReportListGridView from "./views/ReportListGridView";
import LinksView, { LinkDetail } from "../../../../common/links/LinksView";
import { CreateReportRelatedActivityTypeEnum } from "../../../../../api/Client";
import { ValidationVisibilityEnum } from "../../../../../common/validation/ValidationModel";
import validator from "../models/validator";
import ValidationMessageView from "../../../../../common/validation/ValidationMessageView";
import ReportBatchUpdateView from "./views/ReportBatchUpdateView";

interface IRouteParameters {
    reprocessingId: string;
}

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const params = useParams<IRouteParameters>();
    const { reprocessingId } = params;

    const history = useHistory();

    const onCreate = () => {
        history.push(GeoscienceAustraliaRouteHelper.reportCreate(CreateReportRelatedActivityTypeEnum.ReprocessingProject, vm.reprocessingId));
    };

    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const validation = validator(vm, validationVisibility);

    function onSelectBatchUpdate(): void {
        actions.viewChanged(vm.onSelectBatchUpdate());
    }

    function onEditBatchUpdate(): void {
        actions.viewChanged(vm.onEditBatchUpdate());
    }

    function onBatchUpdate(): void {
        if (!validation.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);
        actions.batchUpdateRequested(vm);
    }

    // on mount
    useEffect(() => {
        actions.initialiseRequested(Number(reprocessingId));
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const badges = new Array<React.ReactNode>();
    if (!vm.isPublic) badges.push(<BadgeHelper.NotPublicReprocessingBadge />);

    return (
        <>
            {vm.reprocessingName && vm.reprocessingProjects && (
                <PageLayout
                    title={[`Reprocessing`, vm.reprocessingName]}
                    badges={badges}
                    selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_ReprocessingsProjects_Reprocessings}
                    onReloadRequested={() => {
                        props.clearRequested();
                        props.initialiseRequested(Number(reprocessingId));
                    }}
                >
                    <NavBar reprocessingId={Number(reprocessingId)} activeNavItem={TabsEnum.Reports} />
                    <ConflictAlertView isConflict={vm.isConflict} />
                    <StatusMessagesAlertsView statusMessages={vm.statusMessages} />
                    <DirtyScope scopeName="geoscienceAustralia/reprocessingProjects/project/reports" isDirty={vm.isDirty}></DirtyScope>
                    <LinksView
                        title="Project(s)"
                        canViewLink={true}
                        linkTooltip="Click to view Project Reports."
                        links={vm.reprocessingProjects.map(
                            (reprocessingProject) => new LinkDetail(reprocessingProject.name, GeoscienceAustraliaRouteHelper.reprocessingProjectReports(vm.reprocessingId!, reprocessingProject.id))
                        )}
                    />
                    <ReportListGridView vm={vm} reportUpdated={(report) => actions.viewChanged(vm.onReportUpdated(report))} />
                    {vm.viewState === Models.ViewStateEnum.SelectBatchUpdate && <ValidationMessageView name="batchUpdate.ItemSelected" validationModel={validation} />}
                    {vm.viewState === Models.ViewStateEnum.EditBatchUpdate && <ReportBatchUpdateView vm={vm} updateVm={(m) => actions.viewChanged(m)} v={validation} />}
                    <div className="container-fluid p-2 form">
                        <div className="mt-3">
                            {vm.viewState === Models.ViewStateEnum.View && (
                                <>
                                    {vm.reports.length > 0 && (
                                        <button className="btn btn-primary me-2" onClick={() => onSelectBatchUpdate()}>
                                            Batch Update
                                        </button>
                                    )}
                                    <button type="button" className="btn btn-primary me-2" onClick={onCreate}>
                                        Create Report
                                    </button>
                                </>
                            )}
                            {vm.viewState === Models.ViewStateEnum.SelectBatchUpdate && (
                                <>
                                    <button
                                        className="btn btn-primary me-2"
                                        disabled={!vm.reports.some((item) => item.isSelected) || vm.reports.filter((item) => item.isSelected).length > 100}
                                        onClick={() => onEditBatchUpdate()}
                                    >
                                        Confirm Reports
                                    </button>
                                    <button className="btn btn-secondary me-2" onClick={() => actions.initialiseRequested(Number(reprocessingId))}>
                                        Cancel
                                    </button>
                                </>
                            )}
                            {vm.viewState === Models.ViewStateEnum.EditBatchUpdate && (
                                <>
                                    <button className="btn btn-primary me-2" onClick={() => onBatchUpdate()}>
                                        Batch Update
                                    </button>
                                    <button className="btn btn-secondary me-2" onClick={() => actions.initialiseRequested(Number(reprocessingId))}>
                                        Cancel
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </PageLayout>
            )}
        </>
    );
}
// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_ReprocessingsProjects_ReprocessingReports }), Actions.actionFactory)(Controller);
