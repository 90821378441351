// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
// redux
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import PageLayout from "../../../../../common/shell/PageLayout";
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
// views
import DataMonitoringItemDetailsView from "./views/DataMonitoringItemDetailsView";
import NavBar, { TabsEnum } from "../../components/views/NavBarView";

interface IRouteParameters {
    wellId: string;
    dataMonitoringItemId: string;
}

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const params = useParams<IRouteParameters>();
    const { wellId, dataMonitoringItemId } = params;

    // on mount
    useEffect(() => {
        actions.initialiseRequested(Number(dataMonitoringItemId));
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {vm.dataMonitoringItem.well && (
                <PageLayout
                    title={[`Well`, vm.dataMonitoringItem.well.name]}
                    selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_WellsBoreholes_Wells}
                    onReloadRequested={() => {
                        props.initialiseRequested(Number(dataMonitoringItemId));
                    }}
                >
                    <NavBar wellId={Number(wellId)} activeNavItem={TabsEnum.DataMonitoring} />
                    <DataMonitoringItemDetailsView vm={vm} />
                </PageLayout>
            )}
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_WellsBoreholes_WellDataMonitoringItemDetails }), Actions.actionFactory)(Controller);
