// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export enum ViewStateEnum {
    Initialising,
    View,
}

export interface ISurveyDataItemViewModel {
    id: number;
    versionNumber: number;
    itemId: string;
    isPublic: boolean;
    title: string | undefined;
    dataType: string | undefined;
    tapeId: string | undefined;
    receivedDate: Date | undefined;
    isConfidential: string | undefined;
    confidentialityRemarks: string;
    isSelected: boolean;
    updateFailed: boolean;
    consignment: string | undefined;
    loanStatus: string | undefined;
    conditionStatus: string | undefined;
}

export interface IRootViewModel {
    reprocessingId: number | undefined;
    reprocessingName: string | undefined;
    isPublic: boolean | undefined;

    surveyData: ISurveyDataItemViewModel[];
    reprocessingProjects: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingSurveyDataReprocessingProjectItemDto[];

    referenceData: {
        loanStatuses: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingSurveyDataLoanStatusDto[];
        conditionStatuses: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingSurveyDataConditionStatusDto[];
    };

    statusMessages: Client.StatusMessagesDto | undefined;

    viewState: ViewStateEnum;

    isDirty: boolean;
    isConflict: boolean;

    refreshReprocessingSurveyData(surveyId: number, response: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingSurveyDataResponseDto): IRootViewModel;
    refreshConflict(): IRootViewModel;
}

export class SurveyDataItemViewModel implements ISurveyDataItemViewModel {
    constructor(surveyData: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingSurveyDataItemDto) {
        this.id = surveyData.id;
        this.versionNumber = surveyData.versionNumber;
        this.itemId = surveyData.itemId;
        this.isPublic = surveyData.isPublic;
        this.title = surveyData.title;
        this.dataType = surveyData.dataType;
        this.tapeId = surveyData.tapeId;
        this.receivedDate = surveyData.receivedDate;
        this.reprocessingProjects = surveyData.reprocessingProjects ? surveyData.reprocessingProjects! : [];
        this.reprocessingProjectDescriptions = surveyData.reprocessingProjectDescriptions;
        this.isConfidential = surveyData.isConfidential;
        this.confidentialityRemarks = surveyData.confidentialityRemarks;
        this.isSelected = false;
        this.updateFailed = false;
        this.consignment = surveyData.consignment;
        this.loanStatus = surveyData.loanStatus;
        this.conditionStatus = surveyData.conditionStatus;
    }

    public id: number;
    public versionNumber: number;
    public itemId: string;
    public isPublic: boolean;
    public title: string | undefined;
    public dataType: string | undefined;
    public tapeId: string | undefined;
    public receivedDate: Date | undefined;
    public reprocessingProjects: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingSurveyDataReprocessingProjectItemDto[];
    public reprocessingProjectDescriptions: string | undefined;
    public isConfidential: string | undefined;
    public confidentialityRemarks: string;
    public isSelected: boolean;
    public updateFailed: boolean;
    public consignment: string | undefined;
    public loanStatus: string | undefined;
    public conditionStatus: string | undefined;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.reprocessingId = undefined;
        this.reprocessingName = undefined;
        this.isPublic = undefined;
        this.surveyData = [];
        this.reprocessingProjects = [];

        this.referenceData = {
            loanStatuses: [],
            conditionStatuses: [],
        };

        this.viewState = ViewStateEnum.Initialising;
        this.isDirty = false;
        this.isConflict = false;
    }

    public reprocessingId: number | undefined;
    public reprocessingName: string | undefined;
    public isPublic: boolean | undefined;

    public surveyData: ISurveyDataItemViewModel[];
    public reprocessingProjects: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingSurveyDataReprocessingProjectItemDto[];

    public referenceData: {
        loanStatuses: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingSurveyDataLoanStatusDto[];
        conditionStatuses: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingSurveyDataConditionStatusDto[];
    };

    public statusMessages: Client.StatusMessagesDto | undefined;

    public viewState: ViewStateEnum;

    public isDirty: boolean;
    public isConflict: boolean;

    public refreshReprocessingSurveyData(surveyId: number, response: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingSurveyDataResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.reprocessingId = surveyId;

        vm.reprocessingName = response.reprocessingName;
        vm.isPublic = response.isPublic;
        vm.surveyData = response.items.map((i) => new SurveyDataItemViewModel(i));
        vm.reprocessingProjects = response.reprocessingProjects;

        vm.referenceData = {
            loanStatuses: response.loanStatuses,
            conditionStatuses: response.conditionStatuses,
        };

        vm.viewState = ViewStateEnum.View;

        vm.statusMessages = undefined;

        vm.isDirty = false;
        vm.isConflict = false;

        return vm;
    }

    public refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = true;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.surveyData = clone(this.surveyData);
        vm.reprocessingProjects = clone(this.reprocessingProjects);
        return vm;
    }
}
