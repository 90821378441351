// framework
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
// models
import { IReportItemViewModel, IRootViewModel, ViewStateEnum } from "../../models/models";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import * as BadgeHelper from "../../../../../../common/badges/BadgeHelper";
import * as GeoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";
import CommentSummaryView from "../../../../../../common/commentSummary/commentSummaryView";
import LinksView, { LinkDetail } from "../../../../../common/links/LinksView";

export default function ReportListGridView(props: { vm: IRootViewModel; reportUpdated: (item: IReportItemViewModel) => void }): JSX.Element {
    const { vm, reportUpdated } = props;

    const [reportItemsState, setReportItemsState] = useState(new Array<IReportItemViewModel>());

    const initialState: State = {
        sort: [{ field: "receivedDate", dir: "desc" }],
        filter: { logic: "and", filters: [] },
        skip: 0,
        take: 20,
    };

    const [gridState, setGridState] = useState(initialState);

    // reset grid state when data changes
    useEffect(() => {
        setGridState(initialState);
    }, [vm.reports]); // eslint-disable-line react-hooks/exhaustive-deps

    function onCheckedChanged(item: IReportItemViewModel) {
        const updatedReportItemsState = reportItemsState.map((r) => {
            if (r.id !== item.id) return r;
            return { ...r, isSelected: r.isSelected };
        });

        setReportItemsState(updatedReportItemsState);
        reportUpdated(item);
    }

    useEffect(() => {
        switch (vm.viewState) {
            case ViewStateEnum.View:
                setReportItemsState(vm.reports);
                setGridState(gridState);
                break;
            case ViewStateEnum.SelectBatchUpdate:
                setReportItemsState(vm.reports);
                setGridState(gridState);
                break;
            case ViewStateEnum.EditBatchUpdate:
                setReportItemsState(vm.reports.filter((item) => item.isSelected));
                break;
        }
    }, [vm.viewState, vm.reports, gridState]);

    const rowRender = (trElement: any, props: any) => {
        const failedStyle = {
            backgroundColor: "#F8D7DA",
        };
        const trProps = {
            style: props.dataItem.updateFailed ? failedStyle : undefined,
        };
        return React.cloneElement(
            trElement,
            {
                ...trProps,
            },
            trElement.props.children
        );
    };

    return (
        <>
            <LinksView
                title="Well"
                canViewLink={vm.canViewWells}
                links={[new LinkDetail(vm.wellName, GeoscienceAustraliaRouteHelper.wellReports(vm.wellId!))]}
                linkTooltip="Click to view Well Report."
            />
            <Grid
                pageable
                sortable={vm.viewState === ViewStateEnum.EditBatchUpdate ? false : true}
                resizable
                navigatable
                rowRender={rowRender}
                data={process(reportItemsState, vm.viewState === ViewStateEnum.EditBatchUpdate ? initialState : gridState)}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
                {...gridState}
            >
                {vm.viewState === ViewStateEnum.SelectBatchUpdate && (
                    <GridColumn
                        width={32}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props}>
                                <input type="checkbox" className={Forms.CheckInputClass} checked={props.dataItem.isSelected} onChange={() => onCheckedChanged(props.dataItem)} />
                            </CustomGridCell>
                        )}
                    />
                )}
                <GridColumn
                    field="itemId"
                    title="Item ID"
                    width={140}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <Link to={GeoscienceAustraliaRouteHelper.reportDetails(props.dataItem.id)} title="Click to view Report Details.">
                                {props.dataItem.itemId}
                            </Link>
                        </CustomGridCell>
                    )}
                    filter="text"
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : createGridColumnFilterMenu}
                />
                <GridColumn
                    title="Report Title"
                    field="reportTitle"
                    width={700}
                    sortable={false}
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : createGridColumnFilterMenu}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            {props.dataItem.reportTitle}
                            {!props.dataItem.isPublic && <BadgeHelper.NotPublicReportBadge />}
                        </CustomGridCell>
                    )}
                />
                {vm.viewState === ViewStateEnum.EditBatchUpdate ? (
                    <GridColumn field="reportType" title="Report Type" width={240} />
                ) : (
                    <GridColumn field="reportType" title="Report Type" width={240} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.reports)} />
                )}

                <GridColumn
                    field="receivedDate"
                    title="Received Date"
                    width={190}
                    filter="date"
                    format={GlobalHelpers.NoptaDateFormatForKendo}
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : createGridColumnFilterMenu}
                />

                <GridColumn
                    field="isConfidential"
                    title="Confidentiality"
                    width={190}
                    filter="text"
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : (p) => createGridColumnCheckboxFilterMenu(p, vm.reports)}
                />

                <GridColumn
                    title="Confidentiality Remarks"
                    width={250}
                    sortable={false}
                    field="confidentialityRemarks"
                    filter="text"
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : createGridColumnFilterMenu}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props} className="k-command-cell">
                            <CommentSummaryView comment={props.dataItem.confidentialityRemarks} maxSummaryLength={20} title="Confidentiality Remarks"></CommentSummaryView>
                        </CustomGridCell>
                    )}
                />
                <GridColumn
                    field="conditionStatus"
                    title="Condition Status"
                    width={200}
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : (p) => createGridColumnCheckboxFilterMenu(p, vm.reports)}
                />
                <GridColumn
                    field="loanStatus"
                    title="Loan Status"
                    width={170}
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : (p) => createGridColumnCheckboxFilterMenu(p, vm.reports)}
                />
                <GridColumn
                    title="Consignment"
                    width={250}
                    sortable={false}
                    field="consignment"
                    filter="text"
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : createGridColumnFilterMenu}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props} className="k-command-cell">
                            <CommentSummaryView comment={props.dataItem.consignment} maxSummaryLength={20} title="Consignment"></CommentSummaryView>
                        </CustomGridCell>
                    )}
                />
                <GridColumn
                    field="dateCreated"
                    title="Created Date"
                    width={180}
                    filter="date"
                    format={GlobalHelpers.NoptaDateFormatForKendo}
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : createGridColumnFilterMenu}
                />
                <GridColumn
                    field="dateLastModified"
                    title="Last Modified Date"
                    width={230}
                    filter="date"
                    format={GlobalHelpers.NoptaDateFormatForKendo}
                    columnMenu={vm.viewState === ViewStateEnum.EditBatchUpdate ? undefined : createGridColumnFilterMenu}
                />
            </Grid>
        </>
    );
}
