// framework
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
// redux
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import validator from "../models/validator";
import * as Actions from "../redux/actions";
// shell
import PageLayout from "../../../../../common/shell/PageLayout";
import * as BadgeHelper from "../../../../../common/badges/BadgeHelper";
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
// common
import * as toastHelper from "../../../../../common/toastHelper";
import StatusMessagesAlertsView from "../../../../../common/alerts/StatusMessagesAlertsView";
import ConflictAlertView from "../../../../../common/alerts/ConflictAlertView";
import { ValidationVisibilityEnum } from "../../../../../common/validation/ValidationModel";
import ConfirmationView, { DefaultButtonEnum } from "../../../../../common/confirmation/ConfirmationView";
import * as GeoscienceAustraliaRouteHelper from "../../../../geoscienceAustraliaRouteHelper";
// views
import NavBar, { TabsEnum } from "../../components/views/NavBarView";
import ReadOnlySurveyDataDetailsView from "./views/ReadOnlySurveyDataDetailsView";
import SurveyDataDetailsView from "./views/SurveyDataDetailsView";

interface IRouteParameters {
    surveyDataId: string;
}

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const params = useParams<IRouteParameters>();
    const { surveyDataId } = params;

    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const v = validator(vm, validationVisibility);

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    // on mount
    useEffect(() => {
        actions.initialiseRequested(Number(surveyDataId));
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // on delete
    const history = useHistory();
    useEffect(() => {
        if (vm.viewState === Models.ViewStateEnum.Delete) {
            history.push(GeoscienceAustraliaRouteHelper.surveyDataList());
        }
    }, [vm.viewState]); // eslint-disable-line react-hooks/exhaustive-deps

    const onEdit = () => {
        actions.viewChanged(vm.onEditState());
        window.scrollTo(0, 0);
    };

    const onDelete = () => {
        setShowDeleteConfirmation(true);
    };

    const onDeleteAccepted = () => {
        actions.deleteRequested(vm);
    };

    const onDeleteCancelled = () => {
        setShowDeleteConfirmation(false);
    };

    const onSave = () => {
        if (!v.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.None);
        actions.updateRequested(vm);
        window.scrollTo(0, 0);
    };

    const onCancel = () => {
        actions.loadRequested(vm.surveyDataId!);
        window.scrollTo(0, 0);
    };

    const badges = new Array<React.ReactNode>();
    if (!vm.isPublic) badges.push(<BadgeHelper.NotPublicSurveyDataBadge />);

    return (
        <>
            {vm.surveyDataDetails && vm.surveyDataDetails.publicTitle && (
                <PageLayout
                    title={[`Survey Data`, vm.pageHeader]}
                    badges={badges}
                    selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_ReportsAndData_SurveyData}
                    onReloadRequested={() => {
                        props.initialiseRequested(Number(surveyDataId));
                    }}
                >
                    <NavBar surveyDataId={Number(surveyDataId)} activeNavItem={TabsEnum.Details} />
                    <ConflictAlertView isConflict={vm.isConflict} />
                    <StatusMessagesAlertsView statusMessages={vm.statusMessages} />
                    {vm.viewState === Models.ViewStateEnum.View && (
                        <>
                            <ReadOnlySurveyDataDetailsView vm={vm} />
                            <div className="mt-3">
                                <button type="button" className="btn btn-primary me-2" onClick={onEdit}>
                                    Edit
                                </button>
                                <button type="button" className="btn btn-primary me-2" onClick={onDelete}>
                                    Delete
                                </button>
                            </div>
                        </>
                    )}
                    {vm.viewState === Models.ViewStateEnum.Edit && (
                        <>
                            <SurveyDataDetailsView vm={vm} v={v} actions={actions} />
                            <div className="mt-3">
                                <button type="button" className="btn btn-primary me-2" onClick={onSave}>
                                    Save
                                </button>
                                <button type="button" className="btn btn-secondary me-2" onClick={onCancel}>
                                    Cancel
                                </button>
                            </div>
                        </>
                    )}
                    {showDeleteConfirmation && (
                        <ConfirmationView title="Delete Survey Data" onAccepted={onDeleteAccepted} onClosed={onDeleteCancelled} defaultButton={DefaultButtonEnum.Cancel}>
                            <p>Are you sure you wish to delete this Survey Data?</p>
                            <p>This will delete the Survey Data and Lines, delete any Attachments on the Survey Data, and unlink any Activities to the Survey Data.</p>
                        </ConfirmationView>
                    )}
                </PageLayout>
            )}
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_ReportsAndData_SurveyDataDetails }), Actions.actionFactory)(Controller);
