// framework
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../../../../common/kendoGrid/GridColumnFilterMenu";
// api
import { GetGeoscienceAustraliaReprocessingsProjectsReprocessingProjectsResponseProjectDto } from "../../../../../../api/Client";
// common
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import * as BadgeHelper from "../../../../../../common/badges/BadgeHelper";
import * as geoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";
import CommentSummaryView from "../../../../../../common/commentSummary/commentSummaryView";

export default function ProjectsListGridView(props: {
    reprocessingId: number;
    reprocessingProjects: GetGeoscienceAustraliaReprocessingsProjectsReprocessingProjectsResponseProjectDto[];
}): JSX.Element {
    const { reprocessingId, reprocessingProjects } = props;

    const initialState: State = {
        sort: [{ field: "identifier", dir: "desc" }],
    };

    const [gridState, setGridState] = useState(initialState);
    // reset grid state when data changes
    useEffect(() => {
        setGridState(initialState);
    }, [reprocessingProjects]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Grid
                resizable
                sortable
                data={process(reprocessingProjects, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn
                    field="identifier"
                    title="Project ID"
                    width={200}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <Link to={geoscienceAustraliaRouteHelper.reprocessingProjectDetails(reprocessingId, props.dataItem.id)} title="Click to view Project details.">
                                {props.dataItem.identifier}
                            </Link>
                        </CustomGridCell>
                    )}
                />
                <GridColumn
                    field="name"
                    title="Name"
                    width={500}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            {props.dataItem.name && props.dataItem.name.length > 100 ? <span title={props.dataItem.name}>{props.dataItem.name.substring(0, 97) + `...`}</span> : props.dataItem.name}
                            {!props.dataItem.isPublic && <BadgeHelper.NotPublicReprocessingProjectBadge />}
                        </CustomGridCell>
                    )}
                />
                <GridColumn field="jurisdiction" title="Jurisdiction" width={200} />
                <GridColumn field="reprocessingType" title="Reprocessing Type" width={200} />
                <GridColumn field="finalDeliverableDate" title="Final Deliverable Date" width={200} format={GlobalHelpers.NoptaDateFormatForKendo} />
                <GridColumn field="permitYearEndDate" title="Permit Year End Date" width={200} format={GlobalHelpers.NoptaDateFormatForKendo} />
                <GridColumn field="confidentiality" title="Confidentiality" width={200} />
                <GridColumn field="contractor" title="Contractor" width={200} />
                <GridColumn field="basicRelevantDate" title="Basic Relevant Date" width={200} format={GlobalHelpers.NoptaDateFormatForKendo} />
                <GridColumn field="interpretativeRelevantDate" title="Interpretative Relevant Date" width={240} format={GlobalHelpers.NoptaDateFormatForKendo} />
                <GridColumn
                    field="comments"
                    title="Comments"
                    sortable={false}
                    width={250}
                    columnMenu={createGridColumnFilterMenu}
                    cell={(props: GridCellProps) => (
                        <CustomGridCell gridCellProps={props}>
                            <CommentSummaryView comment={props.dataItem.comments} maxSummaryLength={20} title="Comments"></CommentSummaryView>
                        </CustomGridCell>
                    )}
                />
            </Grid>
        </>
    );
}
