// framework
import { Link } from "react-router-dom";
// api
import { NopimsTitleTypeEnum } from "../../../../../../api/Client";
// models
import { IRootViewModel } from "../../models/models";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import SimpleAuditEventsView from "../../../../../../common/audit/SimpleAuditEventsView";
import * as geoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";

interface IProps {
    vm: IRootViewModel;
}

export default function BoreholeDetailsView(props: IProps): JSX.Element {
    const { vm } = props;

    return (
        <>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">General</h2>
                    <Forms.Row>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="ubhiField" label="UBHI" value={vm.borehole?.ubhi} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="nameField" label="Name" value={vm.borehole?.boreholeName} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="wellNameField" label="Well Name" value={vm.borehole?.wellName} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.Element label="UWI">
                                <Link to={geoscienceAustraliaRouteHelper.wellDetails(vm.borehole?.wellId!)} title="Click to view Well Details.">
                                    {vm.borehole?.uwi}
                                </Link>
                            </Forms.Element>
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="aliasField" label="Alias(es)" value={vm.borehole?.boreholeAlias} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="statusField" label="Status" value={vm.borehole?.boreholeStatus} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="purposeField" label="Purpose" value={vm.borehole?.boreholePurpose} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="typeField" label="Type" value={vm.borehole?.boreholeType} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="nopimsTitleType" label="Title Type" value={vm.borehole?.nopimsTitleTypeName} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            {vm.borehole?.nopimsTitleType === NopimsTitleTypeEnum.Nopta && <Forms.ReadonlyField id="titleField" label="Title" value={vm.borehole?.titleNumber} />}
                            {vm.borehole?.nopimsTitleType !== NopimsTitleTypeEnum.Nopta && <Forms.ReadonlyField id="titleField" label="Title Number" value={vm.borehole?.otherTitleNumber} />}
                        </Forms.QuarterColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField id="operatorField" label="Operator" value={vm.borehole?.operator} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField id="drillingCompanyField" label="Drilling Company" value={vm.borehole?.drillingCompany} />
                        </Forms.HalfColumn>
                        <Forms.HalfColumn>
                            <Forms.ReadonlyField id="rigField" label="Rig" value={vm.borehole?.rig} />
                        </Forms.HalfColumn>
                        <Forms.FullColumn>
                            <Forms.ReadonlyTextArea id="commentsField" label="Comments" value={vm.borehole?.comments} />
                        </Forms.FullColumn>
                        <Forms.FullColumn>
                            <Forms.ReadonlyTextArea id="publicCommentsField" label="Public Comments" value={vm.borehole?.publicComments} />
                        </Forms.FullColumn>
                    </Forms.Row>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Spatial</h2>
                    <Forms.Row>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="bottomHoleLatitudeField" label="Bottom Hole Latitude" value={GlobalHelpers.toNoptaNumberString(vm.borehole?.bottomHoleLatitude, "n8")} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="bottomHoleLongitudeField" label="Bottom Hole Longitude" value={GlobalHelpers.toNoptaNumberString(vm.borehole?.bottomHoleLongitude, "n8")} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="spatialDatumField" label="Spatial Datum" value={vm.borehole?.spatialDatum} />
                        </Forms.QuarterColumn>
                    </Forms.Row>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Depths</h2>
                    <Forms.Row>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="kickOffDepthField" label="Kick Off Depth" value={GlobalHelpers.toNoptaNumberString(vm.borehole?.kickOffDepth, "n2")} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="drillersTotalDepthField" label="Drillers Total Depth" value={GlobalHelpers.toNoptaNumberString(vm.borehole?.drillersTotalDepth, "n2")} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField
                                id="loggerTotalDepthMeasuredDepthField"
                                label="Logger Total Depth Measured Depth"
                                value={GlobalHelpers.toNoptaNumberString(vm.borehole?.loggerTotalDepthMeasuredDepth, "n2")}
                            />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="wellBoreholeDepthReferenceTypeField" label="Borehole Depth Reference" value={vm.borehole?.wellBoreholeDepthReferenceType} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField
                                id="depthReferenceAboveDatumField"
                                label="Depth Reference Above Datum"
                                value={GlobalHelpers.toNoptaNumberString(vm.borehole?.depthReferenceAboveDatum, "n2")}
                            />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField
                                id="trueVerticalDepthSubSeaField"
                                label="True Vertical Depth Sub Sea"
                                value={GlobalHelpers.toNoptaNumberString(vm.borehole?.trueVerticalDepthSubSea, "n2")}
                            />
                        </Forms.QuarterColumn>
                    </Forms.Row>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Dates</h2>
                    <Forms.Row>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="kickOffDateField" label="Kick Off Date" value={GlobalHelpers.toNoptaDateString(vm.borehole?.kickOffDate)} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="totalDepthDateField" label="Total Depth Date" value={GlobalHelpers.toNoptaDateString(vm.borehole?.totalDepthDate)} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="rigReleaseDateField" label="Rig Release Date" value={GlobalHelpers.toNoptaDateString(vm.borehole?.rigReleaseDate)} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn />
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="basicRelevantDateField" label="Basic Relevant Date" value={GlobalHelpers.toNoptaDateString(vm.borehole?.basicRelevantDate)} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="basicReleaseDateField" label="Basic Release Date" value={GlobalHelpers.toNoptaDateString(vm.borehole?.basicReleaseDate)} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField
                                id="interpretativeRelevantDateField"
                                label="Interpretative Relevant Date"
                                value={GlobalHelpers.toNoptaDateString(vm.borehole?.interpretativeRelevantDate)}
                            />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField
                                id="interpretativeReleaseDateField"
                                label="Interpretative Release Date"
                                value={GlobalHelpers.toNoptaDateString(vm.borehole?.interpretativeReleaseDate)}
                            />
                        </Forms.QuarterColumn>
                        <Forms.HalfColumn />
                        <Forms.QuarterColumn />
                        <Forms.QuarterColumn>
                            <Forms.Checkbox id="isAutoCalculateDateField" label="Auto Calculate Dates" checked={vm.borehole?.isAutoCalculateDate} disabled={true} onChange={(v) => {}} />
                        </Forms.QuarterColumn>
                    </Forms.Row>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Financial</h2>
                    <Forms.Row>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="actualHoleCostField" label="Actual Hole Cost" value={GlobalHelpers.toNoptaNumberString(vm.borehole?.actualHoleCost, "c2")} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField
                                id="initialApprovedFinancialExpenditureField"
                                label="Initial Approved Financial Expenditure"
                                value={GlobalHelpers.toNoptaNumberString(vm.borehole?.initialApprovedFinancialExpenditure, "c2")}
                            />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField
                                id="finalApprovedFinancialExpenditureField"
                                label="Final Approved Financial Expenditure"
                                value={GlobalHelpers.toNoptaNumberString(vm.borehole?.finalApprovedFinancialExpenditure, "c2")}
                            />
                        </Forms.QuarterColumn>
                        <Forms.FullColumn>
                            <Forms.ReadonlyTextArea id="costCommentsField" label="Cost Comments" value={vm.borehole?.costComments} />
                        </Forms.FullColumn>
                    </Forms.Row>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Confidentiality</h2>
                    <Forms.Row>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField
                                id="confidentialityField"
                                label="Effective Status"
                                value={GlobalHelpers.convertBooleanToConfidentialityValue(vm.borehole?.confidentiality?.isConfidential)}
                            />
                        </Forms.QuarterColumn>
                        {vm.borehole?.confidentiality.isUserOverridden && (
                            <Forms.QuarterColumn>
                                <Forms.ReadonlyField
                                    id="systemConfidentialityField"
                                    label="System Status"
                                    value={GlobalHelpers.convertBooleanToConfidentialityValue(vm.borehole?.confidentiality?.isSystemConfidential)}
                                />
                            </Forms.QuarterColumn>
                        )}
                        {vm.borehole?.confidentiality.isUserOverridden && (
                            <Forms.QuarterColumn>
                                <Forms.ReadonlyField
                                    id="userConfidentialityField"
                                    label="User Status"
                                    value={GlobalHelpers.convertBooleanToConfidentialityValue(vm.borehole?.confidentiality?.isUserConfidential)}
                                />
                            </Forms.QuarterColumn>
                        )}
                        {vm.borehole?.confidentiality.isUserOverridden && (
                            <Forms.FullColumn>
                                <Forms.ReadonlyTextArea id="confidentialityCommentsField" label="Comments" value={vm.borehole?.confidentiality?.comments} />
                            </Forms.FullColumn>
                        )}
                    </Forms.Row>
                    <div className="mt-2">
                        <SimpleAuditEventsView simpleAuditEvents={vm.borehole?.confidentiality.confidentialityAudit} />
                    </div>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Public Visibility</h2>
                    <Forms.Row>
                        <Forms.QuarterColumn>
                            <Forms.ReadonlyField id="isPublicField" label="Effective Status" value={vm.borehole?.isPublic ? "Public" : "Not Public"} />
                        </Forms.QuarterColumn>
                        {vm.borehole?.isNeverPublicUserOverride && (
                            <>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="systemStatusField" label="System Status" value={vm.borehole?.confidentiality?.isConfidential ? "Not Public" : "Public"} />
                                </Forms.QuarterColumn>
                                <Forms.QuarterColumn>
                                    <Forms.ReadonlyField id="userStatusField" label="User Status" value="Not Public" />
                                </Forms.QuarterColumn>
                                <Forms.FullColumn>
                                    <Forms.ReadonlyTextArea id="neverPublicUserOverrideCommentsField" label="Comments" value={vm.borehole.neverPublicUserOverrideComments} />
                                </Forms.FullColumn>
                            </>
                        )}
                    </Forms.Row>
                </div>
            </div>
            <SimpleAuditEventsView simpleAuditEvents={vm.borehole?.boreholeAudit} />
        </>
    );
}
