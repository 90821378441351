// framework
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// models
import { IParameterItemViewModel, IRootViewModel } from "../../models/models";
// common
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";
import * as geoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";
import { createGridColumnCheckboxFilterMenu } from "../../../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
import { createGridColumnFilterMenu } from "../../../../../../common/kendoGrid/GridColumnFilterMenu";
import { toNoptaNumberString } from "../../../../../../common/GlobalHelpers";
import ConfirmationView, { DefaultButtonEnum } from "../../../../../../common/confirmation/ConfirmationView";

interface IProps {
    vm: IRootViewModel;
    onEdit: (item: IParameterItemViewModel) => void;
    onDelete: (item: IParameterItemViewModel) => void;
}

export default function ParametersListGridView(props: IProps): JSX.Element {
    const { vm, onEdit, onDelete } = props;

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState<IParameterItemViewModel | undefined>(undefined);
    const [parameterItemsState, setParameterItemsState] = useState(new Array<IParameterItemViewModel>());

    const initialState: State = {
        sort: [
            { field: "surveyAcquisition.name", dir: "asc" },
            { field: "shotPointInterval", dir: "asc" },
        ],
        filter: { logic: "and", filters: [] },
        skip: 0,
        take: 10,
    };

    const [gridState, setGridState] = useState(initialState);

    // reset grid state when data changes
    useEffect(() => {
        setGridState(initialState);
        setParameterItemsState(vm.parameters);
    }, [vm.parameters]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setParameterItemsState(vm.parameters);
        setGridState(gridState);
    }, [vm.viewState]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Grid
                pageable
                sortable
                data={process(parameterItemsState, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn
                    field="surveyAcquisition.name"
                    title="Acquisition"
                    width={500}
                    cell={(props: GridCellProps) => (
                        <CustomGridCell gridCellProps={props}>
                            {props.dataItem.surveyAcquisition?.id && vm.canViewAcquisitions ? (
                                <Link to={geoscienceAustraliaRouteHelper.acquisitionParameters(vm.surveyId!, props.dataItem.surveyAcquisition.id)} title="Click to view Acquisition Parameters.">
                                    {props.dataItem.surveyAcquisition.name}
                                </Link>
                            ) : (
                                props.dataItem.surveyAcquisition?.name
                            )}
                        </CustomGridCell>
                    )}
                    filter="text"
                    columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.parameters)}
                />
                <GridColumn
                    field="shotPointInterval"
                    title="Shot Point Interval"
                    width={240}
                    filter="numeric"
                    columnMenu={createGridColumnFilterMenu}
                    cell={(props: GridCellProps) => <td className="text-end">{toNoptaNumberString(props.dataItem.shotPointInterval, "n")}</td>}
                />
                <GridColumn
                    field="receiverGroupInterval"
                    title="Receiver Group Interval"
                    width={240}
                    filter="numeric"
                    columnMenu={createGridColumnFilterMenu}
                    cell={(props: GridCellProps) => <td className="text-end">{toNoptaNumberString(props.dataItem.receiverGroupInterval, "n")}</td>}
                />
                <GridColumn
                    field="groupChannelCount"
                    title="Group Channel Count"
                    width={240}
                    filter="numeric"
                    columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.parameters)}
                    cell={(props: GridCellProps) => <td className="text-end">{toNoptaNumberString(props.dataItem.groupChannelCount, "n")}</td>}
                />
                <GridColumn field="surveyEnergySourceTypeName" title="Energy Source" width={350} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.parameters)} />
                <GridColumn
                    width={140}
                    sortable={false}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props} className="k-command-cell">
                            <button
                                className="btn btn-secondary btn-sm"
                                type="button"
                                title="Edit"
                                onClick={() => {
                                    onEdit(props.dataItem);
                                }}
                            >
                                Edit
                            </button>

                            <button
                                className="btn btn-secondary btn-sm mx-2"
                                type="button"
                                title="Delete"
                                onClick={() => {
                                    setSelectedItem(props.dataItem);
                                    setShowDeleteDialog(true);
                                }}
                            >
                                Delete
                            </button>
                        </CustomGridCell>
                    )}
                />
            </Grid>

            {showDeleteDialog && (
                <ConfirmationView
                    title="Delete Parameter"
                    onAccepted={() => {
                        onDelete(selectedItem!);
                        setShowDeleteDialog(false);
                    }}
                    onClosed={() => setShowDeleteDialog(false)}
                    defaultButton={DefaultButtonEnum.Cancel}
                >
                    <p>Are you sure you want to delete this Parameter?</p>
                </ConfirmationView>
            )}
        </>
    );
}
