// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export enum ViewStateEnum {
    Initialising,
    View,
    Editing,
    Deleting,
}

export interface IParameterItemViewModel {
    id: number | undefined;
    surveyAcquisition: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyParametersResponseAcquisitionDto | undefined;
    shotPointInterval: number | undefined;
    receiverGroupInterval: number | undefined;
    groupChannelCount: number | undefined;
    surveyEnergySourceType: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyParametersResponseSurveyEnergySourceTypeDto | undefined;
    surveyEnergySourceTypeName: string | undefined;
}

export interface IRootViewModel {
    surveyId: number | undefined;
    surveyVersionNumber: number | undefined;
    surveyName: string | undefined;
    isPublic: boolean | undefined;

    surveyAcquisitions: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyParametersResponseAcquisitionDto[];
    surveyEnergySourceTypes: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyParametersResponseSurveyEnergySourceTypeDto[];

    parameters: IParameterItemViewModel[];
    selectedParameter: IParameterItemViewModel | undefined;

    statusMessages: Client.StatusMessagesDto | undefined;

    viewState: ViewStateEnum;

    isBusy: boolean;
    isDirty: boolean;
    isConflict: boolean;

    canViewAcquisitions: boolean;

    refreshSurveyAcquisitionParameters(response: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyParametersResponseDto): IRootViewModel;
    refreshSelectedParameter(parameter: IParameterItemViewModel | undefined): IRootViewModel;
    refreshConflict(): IRootViewModel;

    refreshIsBusy(isBusy: boolean): IRootViewModel;
    refreshViewState(viewState: ViewStateEnum): IRootViewModel;

    refreshStatusMessages(statusMessages: Client.StatusMessagesDto | undefined): IRootViewModel;
}

export class ParameterItemViewModel implements IParameterItemViewModel {
    constructor(parameter: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyParametersResponseParameterDto) {
        this.id = parameter.id;
        this.surveyAcquisition = parameter.acquisition;
        this.shotPointInterval = parameter.shotPointInterval;
        this.receiverGroupInterval = parameter.receiverGroupInterval;
        this.groupChannelCount = parameter.groupChannelCount;
        this.surveyEnergySourceType = parameter.surveyEnergySourceType;
        this.surveyEnergySourceTypeName = parameter.surveyEnergySourceType?.name;
    }

    public id: number | undefined;
    public surveyAcquisition: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyParametersResponseAcquisitionDto | undefined;
    public shotPointInterval: number | undefined;
    public receiverGroupInterval: number | undefined;
    public groupChannelCount: number | undefined;
    public surveyEnergySourceType: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyParametersResponseSurveyEnergySourceTypeDto | undefined;
    public surveyEnergySourceTypeName: string | undefined;
}

export class RootViewModel implements IRootViewModel {
    constructor(id: number) {
        this.surveyId = id;
        this.surveyName = undefined;
        this.isPublic = undefined;

        this.surveyAcquisitions = [];
        this.surveyEnergySourceTypes = [];

        this.parameters = [];

        this.canViewAcquisitions = false;

        this.viewState = ViewStateEnum.Initialising;
        this.isBusy = false;
        this.isDirty = false;
        this.isConflict = false;
    }

    public surveyId: number | undefined;
    public surveyVersionNumber: number | undefined;
    public surveyName: string | undefined;
    public isPublic: boolean | undefined;

    public surveyAcquisitions: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyParametersResponseAcquisitionDto[];
    public surveyEnergySourceTypes: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyParametersResponseSurveyEnergySourceTypeDto[];

    public parameters: IParameterItemViewModel[];
    public selectedParameter: IParameterItemViewModel | undefined;

    public statusMessages: Client.StatusMessagesDto | undefined;

    public viewState: ViewStateEnum;

    public isBusy: boolean;
    public isDirty: boolean;
    public isConflict: boolean;

    public canViewAcquisitions: boolean;

    public refreshSurveyAcquisitionParameters(response: Client.GetGeoscienceAustraliaSurveysAcquisitionsSurveyParametersResponseDto): IRootViewModel {
        const vm = this._clone();

        vm.surveyVersionNumber = response.surveyVersionNumber;
        vm.surveyName = response.surveyName;
        vm.isPublic = response.isPublic;
        vm.parameters = response.parameters.map((i) => new ParameterItemViewModel(i));

        vm.surveyAcquisitions = response.acquisitions;
        vm.surveyEnergySourceTypes = response.surveyEnergySourceTypes;

        vm.canViewAcquisitions = response.canViewAcquisition;

        vm.viewState = ViewStateEnum.View;

        vm.statusMessages = undefined;

        vm.isDirty = false;
        vm.isConflict = false;

        return vm;
    }

    public refreshSelectedParameter(parameter: IParameterItemViewModel | undefined): IRootViewModel {
        const vm = this._clone();

        vm.selectedParameter = parameter;

        return vm;
    }

    public refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = true;
        vm.viewState = ViewStateEnum.View;
        return vm;
    }

    public refreshIsBusy(isBusy: boolean): IRootViewModel {
        const vm = this._clone();
        vm.isBusy = isBusy;
        return vm;
    }

    public refreshViewState(viewState: ViewStateEnum): IRootViewModel {
        const vm = this._clone();
        vm.viewState = viewState;
        return vm;
    }

    public refreshStatusMessages(statusMessages: Client.StatusMessagesDto | undefined): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = statusMessages;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.surveyAcquisitions = clone(this.surveyAcquisitions);
        vm.surveyEnergySourceTypes = clone(this.surveyEnergySourceTypes);
        vm.parameters = clone(this.parameters);
        return vm;
    }
}
