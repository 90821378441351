// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export enum ViewStateEnum {
    Initialising,
    View,
}

export interface ISurveyDataItemViewModel {
    id: number;
    versionNumber: number;
    itemId: string;
    isPublic: boolean;
    title: string | undefined;
    dataType: string | undefined;
    tapeId: string | undefined;
    receivedDate: Date | undefined;
    isConfidential: string | undefined;
    confidentialityRemarks: string;
    isSelected: boolean;
    updateFailed: boolean;
}

export interface IRootViewModel {
    reprocessingId: number | undefined;
    reprocessingName: string | undefined;
    reprocessingProjectId: number | undefined;
    reprocessingProjectName: string | undefined;
    isPublic: boolean | undefined;
    canViewReprocessings: boolean;
    surveyData: ISurveyDataItemViewModel[];

    referenceData: {
        loanStatuses: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingProjectSurveyDataLoanStatusDto[];
        conditionStatuses: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingProjectSurveyDataConditionStatusDto[];
    };

    statusMessages: Client.StatusMessagesDto | undefined;

    viewState: ViewStateEnum;

    isDirty: boolean;
    isConflict: boolean;

    refreshReprocessingProjectSurveyData(surveyId: number, acquisitionId: number, response: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingProjectSurveyDataResponseDto): IRootViewModel;
    refreshConflict(): IRootViewModel;
}

export class SurveyDataItemViewModel implements ISurveyDataItemViewModel {
    constructor(surveyData: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingProjectSurveyDataItemDto) {
        this.id = surveyData.id;
        this.versionNumber = surveyData.versionNumber;
        this.itemId = surveyData.itemId;
        this.isPublic = surveyData.isPublic;
        this.title = surveyData.title;
        this.dataType = surveyData.dataType;
        this.tapeId = surveyData.tapeId;
        this.receivedDate = surveyData.receivedDate;
        this.isConfidential = surveyData.isConfidential;
        this.confidentialityRemarks = surveyData.confidentialityRemarks;
        this.isSelected = false;
        this.updateFailed = false;
        this.consignment = surveyData.consignment;
        this.loanStatus = surveyData.loanStatus;
        this.conditionStatus = surveyData.conditionStatus;
    }

    public id: number;
    public versionNumber: number;
    public itemId: string;
    public isPublic: boolean;
    public title: string | undefined;
    public dataType: string | undefined;
    public tapeId: string | undefined;
    public receivedDate: Date | undefined;
    public isConfidential: string | undefined;
    public confidentialityRemarks: string;
    public isSelected: boolean;
    public updateFailed: boolean;
    public consignment: string | undefined;
    public loanStatus: string | undefined;
    public conditionStatus: string | undefined;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.reprocessingId = undefined;
        this.reprocessingProjectId = undefined;
        this.reprocessingProjectName = undefined;
        this.isPublic = undefined;

        this.surveyData = [];

        this.referenceData = {
            loanStatuses: [],
            conditionStatuses: [],
        };

        this.viewState = ViewStateEnum.Initialising;
        this.isDirty = false;
        this.isConflict = false;
        this.canViewReprocessings = false;
    }

    public reprocessingId: number | undefined;
    public reprocessingName: string | undefined;
    public reprocessingProjectId: number | undefined;
    public reprocessingProjectName: string | undefined;
    public isPublic: boolean | undefined;
    public canViewReprocessings: boolean;

    public surveyData: ISurveyDataItemViewModel[];

    public referenceData: {
        loanStatuses: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingProjectSurveyDataLoanStatusDto[];
        conditionStatuses: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingProjectSurveyDataConditionStatusDto[];
    };

    public statusMessages: Client.StatusMessagesDto | undefined;

    public viewState: ViewStateEnum;

    public isDirty: boolean;
    public isConflict: boolean;

    public refreshReprocessingProjectSurveyData(
        surveyId: number,
        acquisitionId: number,
        response: Client.GetGeoscienceAustraliaReprocessingsProjectsReprocessingProjectSurveyDataResponseDto
    ): IRootViewModel {
        const vm = this._clone();

        vm.reprocessingId = surveyId;
        vm.reprocessingProjectId = acquisitionId;
        vm.reprocessingProjectName = response.reprocessingProjectName;
        vm.isPublic = response.isPublic;
        vm.reprocessingName = response.reprocessingName;
        vm.canViewReprocessings = response.canViewReprocessings;
        vm.surveyData = response.items.map((i) => new SurveyDataItemViewModel(i));

        vm.referenceData = {
            loanStatuses: response.loanStatuses,
            conditionStatuses: response.conditionStatuses,
        };

        vm.statusMessages = undefined;

        vm.viewState = ViewStateEnum.View;
        vm.isDirty = false;
        vm.isConflict = false;

        return vm;
    }

    public refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = true;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.surveyData = clone(this.surveyData);
        vm.referenceData = clone(this.referenceData);
        return vm;
    }
}
