// framework
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
// redux
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// shell
import PageLayout from "../../../../../common/shell/PageLayout";
import * as BadgeHelper from "../../../../../common/badges/BadgeHelper";
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
// common
import SimpleAuditEventsView from "../../../../../common/audit/SimpleAuditEventsView";
// views
import NavBar, { TabsEnum } from "../../components/views/NavBarView";
import ProjectsListGridView from "./views/ProjectsListGridView";

interface IRouteParameters {
    reprocessingId: string;
}

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const params = useParams<IRouteParameters>();
    const { reprocessingId } = params;

    // on mount
    useEffect(() => {
        actions.initialiseRequested(Number(reprocessingId));
        return function () {
            actions.clearRequested();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const badges = new Array<React.ReactNode>();
    if (!vm.reprocessingProjects?.isPublic) badges.push(<BadgeHelper.NotPublicReprocessingBadge />);

    return (
        <>
            {vm.reprocessingProjects && (
                <PageLayout
                    title={[`Reprocessing`, vm.reprocessingProjects.name]}
                    badges={badges}
                    selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_ReprocessingsProjects_Reprocessings}
                    onReloadRequested={() => {
                        props.initialiseRequested(Number(reprocessingId));
                    }}
                >
                    <NavBar reprocessingId={Number(reprocessingId)} activeNavItem={TabsEnum.Projects} />
                    <ProjectsListGridView reprocessingId={Number(reprocessingId)} reprocessingProjects={vm.reprocessingProjects.projects} />
                    <SimpleAuditEventsView simpleAuditEvents={vm.reprocessingProjects.audit} />
                </PageLayout>
            )}
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_ReprocessingsProjects_ReprocessingProjects }), Actions.actionFactory)(Controller);
